import { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Select,
  Typography,
  FormControl,
  Chip,
  InputLabel,
  Stack,
} from "@mui/material";
import { CHECK_IN_OUT, SERVICE_TYPE } from "./const";
import MDDatePicker from "components/MDDatePicker";
import EditIcon from "@mui/icons-material/Edit";
import EditRoomTypeDialog from "./EditRoomTypeDialog";
import Extras from "./Extras";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import AddPassenger from "./AddPassenger";
import { useFormContext } from "../../FormContextProvide";
import { useRequirmentContext } from "../../RequirmentContextProvider";
import PassengerInfo from "./PassengerInfo";
import { diffDaysOfTwoDate } from "utils/helpers";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

interface RoomProps {
  roomIndex: number;
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
}
const RoomCard = ({ roomIndex, setErrors }: RoomProps) => {
  //hooks
  const { formContext, setFormContext } = useFormContext();
  const { requirmentContext } = useRequirmentContext();

  //states
  const [isOpenEditRoomType, setIsOpenEditRoomType] = useState(false);
  const [nights, setNights] = useState<number>(0);

  const handleAddPassenger = (user: any) => {
    console.log(user);

    const newRooms = formContext.rooms;
    newRooms[roomIndex] = {
      ...newRooms[roomIndex],
      pax: newRooms[roomIndex].passengers.length + 1,
      passengers: [
        ...newRooms[roomIndex].passengers,
        {
          id: uuidv4(),
          user_id: user?.user_id,
          label: user?.fullname,
          fullname: user?.fullname,
          company: user?.company,
          user_type: user?.user_type,
        },
      ],
    };

    setFormContext({ ...formContext, rooms: newRooms });
  };

  useEffect(() => {
    const newRooms = formContext.rooms;
    newRooms[roomIndex] = {
      ...newRooms[roomIndex],
      // passengerSellPrice: false,
      room_index: roomIndex,
    };
    setFormContext({ ...formContext, rooms: newRooms });
  }, []);

  return (
    <Grid container sx={{ backgroundColor: "#e9ecef", p: 2, mb: 3, borderRadius: 4 }}>
      <Grid
        xs={12}
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: 1,
          paddingBottom: "1.5rem",
        }}
      >
        <Grid container>
          <Grid sm={10} xs={12}>
            <Stack direction="row" justifyContent="flex-start">
              <Typography pt={1.5}>
                {`${formContext?.rooms[roomIndex]?.hotel_title} - ${formContext?.rooms[roomIndex]?.room_type1_title} > ${formContext?.rooms[roomIndex]?.room_type2_title}`}
                (#{roomIndex + 1})
              </Typography>
              <IconButton
                aria-label="edit"
                style={{ marginLeft: "1rem" }}
                onClick={() => setIsOpenEditRoomType(true)}
              >
                <EditIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid sm={2} xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <IconButton sx={{ marginRight: "10px !important" }}>
                <AttachMoneyIcon
                  onClick={() => {
                    const oldPassengerSellPrice = formContext.rooms[roomIndex]?.passengerSellPrice;
                    const newRooms = formContext.rooms;
                    newRooms[roomIndex] = {
                      ...newRooms[roomIndex],
                      passengerSellPrice: !oldPassengerSellPrice,
                      room_index: roomIndex,
                    };
                    setFormContext({ ...formContext, rooms: newRooms });
                  }}
                />
              </IconButton>
              <AddPassenger handleAdd={handleAddPassenger} />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      {formContext?.rooms[roomIndex]?.passengers?.map((item: any, index: number) => (
        <Grid xs={12} item>
          <PassengerInfo passengerIndex={index} roomIndex={roomIndex} id={item?.id} />
        </Grid>
      ))}
      <Grid xs={12} item>
        <Divider sx={{ borderWidth: "1px" }} />
      </Grid>
      <Grid xs={12} item>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth sx={{ position: "relative" }}>
              <MDDatePicker
                label={CHECK_IN_OUT}
                input={{ placeholder: CHECK_IN_OUT, sx: { width: "100%" } }}
                options={{
                  mode: "range",
                  allowInput: true,
                  onChange: (e: any) => {
                    const newRooms = formContext.rooms;
                    newRooms[roomIndex] = {
                      ...newRooms[roomIndex],
                      checkIn: moment(e?.[0]).format("YYYY-MM-DD"),
                      checkOut: moment(e?.[1]).format("YYYY-MM-DD"),
                      nights: diffDaysOfTwoDate(new Date(e?.[0]), new Date(e?.[1])),
                    };
                    setNights(diffDaysOfTwoDate(new Date(e?.[0]), new Date(e?.[1])));
                    setFormContext({
                      ...formContext,
                      rooms: newRooms,
                    });
                  },
                }}
              />
              {nights > 0 && (
                <Chip
                  color="secondary"
                  variant="outlined"
                  sx={{
                    position: "absolute",
                    top: "12px",
                    right: "5px",
                    fontSize: ".7rem",
                    borderRadius: "8px",
                  }}
                  label={`${nights} nights`}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel id="pansionType">{SERVICE_TYPE}</InputLabel>
              <Select
                native
                label={SERVICE_TYPE}
                fullWidth
                required
                name="pansionType"
                id="pansionType"
                onChange={(e: any) => {
                  const pansionType = e.target.value && JSON.parse(e.target.value);
                  const newRooms = formContext.rooms;
                  console.log(pansionType);

                  newRooms[roomIndex] = {
                    ...newRooms[roomIndex],
                    pansiyon: pansionType?.id,
                    pansiyonCode: pansionType?.code,
                  };
                  setFormContext({ ...formContext, rooms: newRooms });
                }}
              >
                <option aria-label="None" value="" />
                {requirmentContext?.pansion_types?.map((item: any) => (
                  <option
                    value={JSON.stringify({
                      id: item.type_id,
                      code: item.type_code,
                    })}
                  >
                    {item.type_code}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ borderWidth: "1px" }} />
      <Grid xs={12} item>
        <Extras roomIndex={roomIndex} />
      </Grid>

      <EditRoomTypeDialog
        open={isOpenEditRoomType}
        onClose={() => setIsOpenEditRoomType(false)}
        roomIndex={roomIndex}
      />
    </Grid>
  );
};

export default RoomCard;
