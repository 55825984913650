import React, { useEffect, useState } from "react";
import { CANCEL, DESCRIPTION, HOTEL, NAME, SEARCH, SELECT_HOTEL } from "./constants";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FilterAlt from "@mui/icons-material/FilterAlt";
import Paper from "@mui/material/Paper";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { SELECT_USER } from "store/slices/profile";
import Post from "lib/api/post";
import MDButton from "components/MDButton";
import { Clear } from "@mui/icons-material";

interface HotelFilterType {
  setFilerHotelValue: (T: any) => typeof T;
  filerHotelValue: any;
}

const HotelFilter = ({ setFilerHotelValue, filerHotelValue }: HotelFilterType) => {
  const [open, setOpen] = useState(false);
  const [hotelList, setHotelList] = useState(null);
  const [page, setPage] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setInputValue("");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
    p: 2,
    borderRadius: "8px",
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "gray",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const user = SELECT_USER();
  useEffect(() => {
    setIsLoading(true);
    const payload = {
      user_id: user?.uid,
      session_id: user?.session_id,
      type_level: 0,
      key: inputValue,
      page: page,
      count: 10,
    };
    Post.hotelList(payload)
      .then((res) => {
        setHotelList(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [inputValue, page, user?.session_id, user?.uid]);

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleRemove = () => {
    setFilerHotelValue(null);
  };

  return (
    <>
      <Grid item display="flex" py={2} xs={6}>
        <Typography onClick={handleOpen} sx={{ cursor: "pointer" }}>
          {HOTEL}
        </Typography>
        <Box mt={0.5} ml={0.5} onClick={handleOpen} sx={{ cursor: "pointer" }}>
          <FilterAlt />
        </Box>
        {filerHotelValue?.fullname && (
          <>
            <Typography fontSize="14px" ml={2} mt={0.8}>
              {filerHotelValue?.fullname}
            </Typography>
            <Box mt={0.5} ml={2} sx={{ cursor: "pointer" }} onClick={handleRemove}>
              <Clear color="error" />
            </Box>
          </>
        )}
      </Grid>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography>{SELECT_HOTEL}</Typography>
          <Divider sx={{ borderWidth: "1px" }} />
          <TextField
            placeholder={SEARCH}
            fullWidth
            value={inputValue}
            onChange={(e: any) => setInputValue(e.target.value)}
          />
          {isLoading && (
            <Box sx={{ position: "relative" }}>
              <CircularProgress
                size="2.5rem"
                color="secondary"
                sx={{ position: "absolute", left: "50%" }}
              />
            </Box>
          )}
          <Box my={1}>
            <TableContainer component={Paper} sx={{ maxHeight: "40vh" }}>
              <Table sx={{ minWidth: 500 }} aria-label="customized table" stickyHeader>
                <TableHead sx={{ display: "contents" }}>
                  <TableRow>
                    <StyledTableCell>{NAME}</StyledTableCell>
                    <StyledTableCell>{DESCRIPTION}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hotelList?.items?.map((hotel: any) => (
                    <StyledTableRow
                      key={hotel?.hotel_id}
                      onClick={() => {
                        setFilerHotelValue(hotel);
                        handleClose();
                      }}
                    >
                      <StyledTableCell sx={{ cursor: "pointer" }}>
                        {hotel?.fullname}
                      </StyledTableCell>
                      <StyledTableCell>
                        {hotel?.district_name} - {hotel?.state_name}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Pagination
            count={Math.ceil(hotelList?.count / 10)}
            showFirstButton
            showLastButton
            onChange={handlePaginationChange}
            disabled={isLoading}
          />
          <Divider sx={{ borderWidth: "1px" }} />
          <Grid display="flex" justifyContent="flex-end">
            <MDButton color="error" onClick={handleClose}>
              {CANCEL}
            </MDButton>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default HotelFilter;
