import AccordionArea from "./components/AccordionArea";
import Item from "./components/Item";
import RoomItem from "./components/RoomItem";
import { Button, Grid, Stack } from "@mui/material";
import { useFormContext } from "../FormContextProvide";
import { NEXT, PREVIOUS } from "../const";

type Props = {
  next: () => void;
  back: () => void;
  activeStep: number;
};

const ExtraCostsTabContent = ({ next, back, activeStep }: Props) => {
  const { formContext } = useFormContext();

  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <AccordionArea />
      </Grid>
      <Grid xs={12} item>
        <Item />
      </Grid>
      <Grid xs={12} item>
        {formContext?.rooms?.map((room: any, index: number) => {
          return <RoomItem room={room} index={index} />;
        })}
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" py={2} justifyContent="space-between">
          <Button
            variant="contained"
            color="primary"
            disabled={activeStep === 0}
            onClick={back}
            sx={{ mr: 1 }}
          >
            {PREVIOUS}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              next?.();
            }}
          >
            {NEXT}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ExtraCostsTabContent;
