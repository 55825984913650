import { useCallback, useEffect, useMemo, useState } from "react";

// @mui material components
import { Grid, Icon, Card, Link, MenuItem, Menu } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { SELECT_USER } from "store/slices/profile";
import Post from "lib/api/post";
import AddCurrency from "./add";
import Remove from "./remove";
interface OptionType {
  id: number;
name: string;
code: string;
order: number;
}

function Currencies(): JSX.Element {
  const user = SELECT_USER();

  const [hotels, setHotels] = useState<readonly OptionType[]>([]);
  const [currId, setCurrId] = useState<number>();
  const [openAddCurr, setOpenAddCurr] = useState(false);
  const [openRemoveHotel, setOpenRemoveHotel] = useState(false);

  const [_searchedValue, _setSearchedValue] = useState<string>("");
  const [_openMenu, _setOpenMenu] = useState<any>(false);
  const [_updatePage, _setUpdatePage] = useState<number>(0);
  const [selectedCurrency, setSelectedCurrency] = useState<OptionType | null>(null);  // برای ذخیره ارز انتخاب‌شده
  const [openEditDialog, setOpenEditDialog] = useState(false);  // برای باز و بسته کردن دیالوگ ویرایش

  const fetchCurrencies = useCallback(() => {
    Post.searchCurrencies(user.session_id, user.uid, _searchedValue).then((res) => {
      setHotels(res.data.items);
    });
  }, [_searchedValue, user.session_id, user.uid]);
  

  const handleEditCurrency = (currency: OptionType) => {
    setSelectedCurrency(currency);  // ارز انتخاب‌شده رو ذخیره می‌کنیم
    setOpenEditDialog(true);  // دیالوگ ویرایش رو باز می‌کنیم
  };
  useEffect(() => {
    if (_searchedValue === "") return;
    fetchCurrencies();  // اولین بار که صفحه بارگذاری می‌شود، لیست ارزها فراخوانی می‌شود
  }, [_searchedValue, fetchCurrencies]);


  const handleCloseMenu = useCallback(() => _setOpenMenu(false), []);

  const handleOpenAddHotel = useCallback(() => {
    handleCloseMenu();
    setOpenAddCurr(true);
  }, [handleCloseMenu]);

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);  // بستن دیالوگ ویرایش
    fetchCurrencies();  // به‌روزرسانی لیست ارزها بعد از افزودن ارز
  };
  const handleOpenAddDialog = () => {
    setOpenAddCurr(true);  // باز کردن دیالوگ برای اضافه کردن ارز
  };
  
  const handleCloseAddDialog = () => {
    setOpenAddCurr(false);  // بستن دیالوگ برای اضافه کردن ارز
    fetchCurrencies();  // به‌روزرسانی لیست ارزها بعد از افزودن ارز
  };

  const handleOpenRemoveHotel = useCallback(() => {
    handleCloseMenu();
    setOpenRemoveHotel(true);
  }, []);

  const handleOpenMenu = useCallback((event: any, currency: OptionType) => {
    setCurrId(currency.id);  // شناسه ارز رو تنظیم می‌کنیم
    setSelectedCurrency(currency);  // اطلاعات ارز انتخاب‌شده رو تنظیم می‌کنیم
    _setOpenMenu(event.currentTarget);  // منو رو باز می‌کنیم
  }, []);

  const handleRemoveSuccess = () => {
    setOpenRemoveHotel(false);
    fetchCurrencies();  // به‌روزرسانی لیست ارزها بعد از حذف ارز
  };

  const renderMenu = useMemo(() => {
    return (
      <>
        <Menu
          anchorEl={_openMenu}
          anchorReference={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={Boolean(_openMenu)}
          onClose={handleCloseMenu}
          sx={{ mt: 2 }}
        >
          {/* برای ویرایش از handleEditCurrency استفاده می‌کنیم */}
          <MenuItem onClick={() => handleEditCurrency(selectedCurrency!)}>
            <Icon sx={{ mr: 1 }}>edit</Icon>Modify
          </MenuItem>
          
          <MenuItem onClick={handleOpenRemoveHotel}>
            <Icon sx={{ mr: 1 }}>delete</Icon>Remove
          </MenuItem>
        </Menu>
  
        {openEditDialog && (
        <AddCurrency
          currAdded={(v: any): void => {}}
          currencyToEdit={selectedCurrency}  // ارسال ارز انتخاب‌شده به AddCurrency
          isEdit={true}
          openDia={openEditDialog}  // کنترل باز بودن دیالوگ
          onClose={handleCloseEditDialog}  // برای بستن دیالوگ
        />
      )}
      </>
    );
  }, [_openMenu, currId, handleEditCurrency, handleOpenRemoveHotel, handleCloseMenu]);
  
  const renderTable = useMemo(() => {
    return (
      <DataTable
        isSorted={false}
        table={{
          columns: [
            {
              Header: (
                <MDTypography variant="h6" fontWeight="bold">
                  #CODE
                </MDTypography>
              ),
              accessor: "code",
            },
            {
              Header: (
                <MDTypography variant="h6" fontWeight="bold">
                  Name
                </MDTypography>
              ),
              accessor: "name",
            },
            {
              Header: <Icon fontSize="medium">view_list</Icon>,
              accessor: "actions",
              width: "12%",
            },
          ],
          rows: hotels.map((currency, i) => {
            const hotelObj = {
              code: (
                <>
                  <MDTypography variant="inherit" color="text" fontWeight="bold">
                    {currency.code}
                  </MDTypography>
                </>
              ),
              name: (
                <>
                  <MDTypography variant="inherit" color="text" fontWeight="bold">
                    {currency.name}
                  </MDTypography>
                </>
              ),

              actions: (
                <>
                  <MDButton
                    id={currency.id.toString()}
                    key={i}
                    variant="contained"
                    color="light"
                    onClick={(e) => handleOpenMenu(e, currency)}  // ارسال currency به handleOpenMenu
                  >
                    <Icon>view_list</Icon>
                    &nbsp;Actions
                  </MDButton>
                </>
              ),
            };
            return hotelObj;
          }),
        }}
        entriesPerPage={false}
        pagination={{
          variant: "contained",
          color: "primary",
        }}
        showTotalEntries={false}
      />
    );
  }, [handleOpenMenu, hotels]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container>
            <Card sx={{ width: "100%" }}>
              <Grid container p={3} spacing={1}>
                <Grid item sx={{ width: "70%" }}>
                  <MDInput
                    sx={{ width: "100%" }}
                    label="Search"
                    value={_searchedValue}
                    onChange={(e: { target: { value: string } }) =>
                      _setSearchedValue(e.target.value)
                    }
                  ></MDInput>
                </Grid>
                <Grid item sx={{ marginLeft: "auto" }}>
                  
                  <Grid container justifyContent="flex-end">
                  <MDButton onClick={() => setOpenAddCurr(true)} color={"primary"}>
                      + Add Currency
                  </MDButton>
                    <AddCurrency  currAdded={(v: any): void => {}} 
                      openDia={openAddCurr}  // ارسال وضعیت باز بودن دیالوگ برای اضافه کردن
                      onClose={handleCloseAddDialog}  // تابع بستن دیالوگ
                      />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {renderMenu}
                {renderTable}
              </Grid>
{/* 
              {openRemoveHotel ? (
                <Grid item xs={12}>
                  <Remove
                    curr_id={currId}
                    open={openRemoveHotel}
                    setOpen={setOpenRemoveHotel}
                    removedCurr={handleRemoveSuccess}
                  />
                </Grid>
              ) : null} */}
            </Card>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {/* {openEditDialog && (
        <AddCurrency
          currAdded={(v: any): void => {}}
          currencyToEdit={selectedCurrency}  // ارسال ارز انتخاب‌شده به AddCurrency
          isEdit={true}
        />
      )} */}

      
    </>
  );
}

export default Currencies;
