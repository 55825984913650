import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControl, Select, Stack, TextField } from "@mui/material";
import { AGENCY_NOTE, HOTEL_NOTE, IMAGES, OPERATION_NOTE, VOUCHER_IMAGE } from "./const";
import { useRequirmentContext } from "../../RequirmentContextProvider";
import { useFormContext } from "../../FormContextProvide";

const AccordionArea = () => {
  const { requirmentContext } = useRequirmentContext();
  const { formContext, setFormContext } = useFormContext();

  const voucherImage =
    requirmentContext?.voucher_images && JSON.parse(requirmentContext?.voucher_images);

  return (
    <Stack direction="column" spacing={3}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ padding: "0 1rem" }}
        >
          <Typography>{HOTEL_NOTE}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl fullWidth>
            <TextField
              name={"hotel_note"}
              label={HOTEL_NOTE}
              multiline
              rows={4}
              onChange={(e) => setFormContext({ ...formContext, hotel_note: e.target.value })}
            />
          </FormControl>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ padding: "0 1rem" }}
        >
          <Typography>{OPERATION_NOTE}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl fullWidth>
            <TextField
              name="operation_note"
              label={OPERATION_NOTE}
              multiline
              rows={4}
              onChange={(e) => setFormContext({ ...formContext, operation_note: e.target.value })}
            />
          </FormControl>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ padding: "0 1rem" }}
        >
          <Typography>{AGENCY_NOTE}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl fullWidth>
            <TextField
              name="agency_note"
              label={AGENCY_NOTE}
              multiline
              rows={4}
              onChange={(e) => setFormContext({ ...formContext, agency_note: e.target.value })}
            />
          </FormControl>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          style={{ padding: "0 1rem" }}
        >
          <Typography>{VOUCHER_IMAGE}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl fullWidth required>
            <Select
              native
              fullWidth
              name="voucher_image"
              required
              onChange={(e: any) => {
                const value = JSON.parse(e.target.value);
                setFormContext({ ...formContext, voucher_image: value?.img });
              }}
            >
              <optgroup label={IMAGES}>
                {voucherImage?.images?.map((item: any) => (
                  <option
                    key={item.title}
                    value={JSON.stringify({
                      img: item.img,
                      title: item.title,
                    })}
                  >
                    {item.title}
                  </option>
                ))}
              </optgroup>
            </Select>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default AccordionArea;
