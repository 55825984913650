import Modal from "@mui/material/Modal";
import { Box, FormControl, TextField, Typography, Divider, Grid, Button } from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { SELECT_USER } from "store/slices/profile";
import { MODAL_STYLE } from "../../../style";
import Post from "lib/api/post";
import { ADD_AIRLINE, AIRLINE_CODE, CANCEL, ORDER, SUBMIT, TITLE } from "../const";
import Put from "lib/api/put";

interface AddAirLineDialogType {
  open: boolean;
  onClose: (T: any) => typeof T;
  initialData?: {
    airline_id: number,
    airline_code: string;
    order: number;
    title: string;
  };
}

function AddAirLine({ open, onClose, initialData }: AddAirLineDialogType) {
  const user = SELECT_USER();
  const { handleSubmit, handleChange, handleBlur, values, setValues, resetForm } = useFormik<any>({
    initialValues: {
      airline_code: initialData?.airline_code || "",
      order: initialData?.order || 0,
      airline_id: initialData?.airline_id || 0,
      title: initialData?.title || "",
    },
    onSubmit: (value) => {
      const payload = {
        airline_code: value?.airline_code,
        airline_id: initialData?.airline_id,
        order: value?.order,
        title: value?.title,
        user_id: user?.uid,
        session_id: user?.session_id,
      };
      const apiCall = initialData ? Put.updateAirline : Post.addAirline;
      apiCall(payload).then(() => {
        onClose(false);
        resetForm();
      });
    },
  });
  useEffect(() => {
    if (initialData) {
      setValues({
        airline_code: initialData.airline_code,
        order: initialData.order,
        title: initialData.title,
      });
    }
  }, [initialData, setValues]);

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={MODAL_STYLE} component={"form"} onSubmit={handleSubmit}>
          <Typography>{ADD_AIRLINE}</Typography>
          <Divider sx={{ borderWidth: "1px" }} />
          <Grid container>
            <Grid xs={6}>
              <Box p={1}>
                <FormControl fullWidth required>
                  <TextField
                    required
                    label={AIRLINE_CODE}
                    variant="outlined"
                    name="airline_code"
                    value={values.airline_code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid xs={6}>
              <Box p={1}>
                <FormControl fullWidth required>
                  <TextField
                    required
                    label={TITLE}
                    variant="outlined"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box p={1}>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    label={ORDER}
                    variant="outlined"
                    name="order"
                    value={values.order}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          <Divider sx={{ borderWidth: "1px" }} />
          <Grid display="flex" justifyContent="flex-end">
            <Box sx={{ mr: 2 }}>
              <Button variant="contained" color="secondary" onClick={() => onClose(false)}>
                {CANCEL}
              </Button>
            </Box>

            <Box>
              <Button variant="contained" color="primary" type="submit">
                {SUBMIT}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default AddAirLine;
