import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { BUY_PRICE, CURRENCY, HOTEL, SELL_PRICE, TITLE, TYPE } from "./const";
import { Switch } from "@material-ui/core";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useFormik } from "formik";
import { useRequirmentContext } from "../../RequirmentContextProvider";
interface ICostFormProps {
  id: string;
  handleDelete: any;
  order?: number;
  handleChangeValue?: any;
}
const CostForm = ({ id, handleDelete, order, handleChangeValue }: ICostFormProps) => {
  const { requirmentContext } = useRequirmentContext();

  const { handleChange, handleBlur, values } = useFormik<any>({
    initialValues: {
      buyPriceCurrency: 1,
      buyPriceCurrencyCode: "USD",
      sellPriceCurrency: 1,
      sellPriceCurrencyCode: "USD",
      extra_id: 0,
      id,
      order,
    },
    onSubmit: (values) => {},
  });

  return (
    <Card variant="outlined" sx={{ p: 2, mb: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            onClick={() => handleDelete(id)}
          >
            <DeleteOutlineIcon />
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="column" spacing={1.25}>
            <FormControl fullWidth required>
              <TextField
                size="small"
                label={TITLE}
                required
                name="title"
                value={values.title}
                onChange={(e) => {
                  handleChange(e);
                  handleChangeValue?.({ data: { title: e.target.value }, values });
                }}
                onBlur={handleBlur}
              />
            </FormControl>
            <FormControl fullWidth required>
              <InputLabel id="extra_id">{TYPE}</InputLabel>
              <Select
                native
                label={TYPE}
                labelId="extra_id"
                fullWidth
                name="extra_id"
                required
                value={values.extra_id}
                onBlur={handleBlur}
                onChange={(e: any) => {
                  handleChange(e);
                  handleChangeValue?.({ data: { extraType: e.target.value }, values });
                }}
              >
                <option value=""></option>
                {requirmentContext?.cost_types?.map((item: any) => (
                  <option key={item.id} value={item.id}>
                    {item.title}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Stack direction="column" spacing={1.25}>
            <FormControl fullWidth required sx={{ mb: 1 }}>
              <TextField
                label={BUY_PRICE}
                required
                type="number"
                variant="outlined"
                name="buyPrice"
                value={values.buyPrice}
                onBlur={handleBlur}
                onChange={(e: any) => {
                  handleChange(e);
                  handleChangeValue?.({ data: { buyPrice: e.target.value }, values });
                }}
              />
            </FormControl>
            <FormControl fullWidth required>
              <InputLabel id="buyPriceCurrency">{CURRENCY}</InputLabel>
              <Select
                native
                required
                fullWidth
                label={CURRENCY}
                labelId="buyPriceCurrency"
                name="buyPriceCurrency"
                value={values.buyPriceCurrency}
                onBlur={handleBlur}
                onChange={(e: any) => {
                  const value = JSON.parse(e.target.value);
                  handleChange(e);
                  handleChangeValue?.({
                    data: { buyPriceCurrency: value.id, buyPriceCurrencyCode: value.code },
                    values,
                  });
                }}
              >
                {requirmentContext?.currencies?.map((item: any) => (
                  <option
                    key={item.id}
                    value={JSON.stringify({
                      id: item.id,
                      code: item.code,
                    })}
                  >
                    {item.code}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={values.hotel_based}
                  value={values.hotel_based}
                  onChange={(e) => {
                    handleChange(e);
                    handleChangeValue?.({
                      data: { hotel_based: e?.target?.checked ? 1 : 0 },
                      values,
                    });
                  }}
                  onBlur={handleBlur}
                  name="hotel_based"
                  color="primary"
                />
              }
              label={HOTEL}
            />
          </Stack>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Stack direction="column" spacing={1.25}>
            <FormControl fullWidth required>
              <TextField
                required
                label={SELL_PRICE}
                type="number"
                variant="outlined"
                name="sellPrice"
                value={values.sellPrice}
                onBlur={handleBlur}
                onChange={(e: any) => {
                  handleChange(e);
                  handleChangeValue?.({ data: { sellPrice: e.target.value }, values });
                }}
              />
            </FormControl>
            <FormControl fullWidth required>
              <InputLabel id="sellPriceCurrency">{CURRENCY}</InputLabel>
              <Select
                native
                required
                fullWidth
                label={CURRENCY}
                labelId="sellPriceCurrency"
                name="sellPriceCurrency"
                value={values.sellPriceCurrency}
                onChange={(e: any) => {
                  const value = JSON.parse(e.target.value);
                  handleChangeValue?.({
                    data: { sellPriceCurrency: value.id, sellPriceCurrencyCode: value.code },
                    values,
                  });

                  handleChange(e);
                }}
                onBlur={handleBlur}
              >
                {requirmentContext?.currencies?.map((item: any) => (
                  <option
                    key={item.id}
                    value={JSON.stringify({
                      id: item.id,
                      code: item.code,
                    })}
                  >
                    {item.code}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CostForm;
