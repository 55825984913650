import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import DataProvider from "./DataProvider";
import ReservationContent from "./ReservationContent";
import RequirmentContextProvider from "./RequirmentContextProvider";
import FormContextProvider from "./FormContextProvide";
import { useContext } from "react";

const ReservationPage = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <RequirmentContextProvider>
        <FormContextProvider>
          <ReservationContent />
        </FormContextProvider>
      </RequirmentContextProvider>
    </DashboardLayout>
  );
};

export default ReservationPage;

