import * as yup from "yup";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import Post from "lib/api/post";
import Put from "lib/api/put";
import { useEffect, useState } from "react";
import { SELECT_USER } from "store/slices/profile";

interface FormType {
    type_id: number;
    type_code: string;
    title:string;
  min_pax: number;
  max_pax: number;
  meta_tags:string;
  active:number;
}

const validationSchema = () =>
  yup.object().shape({
    type_id: yup.string().required("required"),
    type_code: yup.string().required("required"),
    title:yup.string().required("required"),
  min_pax: yup.string().required("required"),
  max_pax: yup.string().required("required"),
  mega_tags:yup.string().required("required"),
  active:yup.string().required("required"),
  });

interface PropsType {
  carTypeAdded: (v: any) => void;
  carTypeToEdit?: FormType;  // داده ارز برای ویرایش
  isEdit?: boolean;  // مشخص می‌کنه که فرم در حالت ویرایش هست
  openDia: boolean;  // وضعیت باز بودن دیالوگ از والد دریافت می‌شود
  onClose: () => void;  // تابع بستن دیالوگ
}

const AddCarType = ({ carTypeAdded: carTypAdded, carTypeToEdit: carTypToEdit, isEdit = false, openDia, onClose  }: PropsType) => {
  const user = SELECT_USER();
  const [open, setOpen] = useState(false);
//   const [open_hotel_type, setOpen_hotel_type] = useState(false);
//   const [open_district, setOpen_district] = useState(false);
//   const [options, setOptions] = useState<readonly OptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
//   const hotel_type_loading = open_hotel_type;
//   const [district_options, set_district_options] = useState<DistrictOptionType[]>([]);
//   const [district_search, set_district_search] = useState<string>("");
//   const [district_loading, set_district_loading] = useState<boolean>(false);

  const {
    handleSubmit,
    resetForm,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
    setValues,
  } = useFormik<FormType>({
    validationSchema,
    initialValues: {
        type_id: carTypToEdit?.type_id || 0,
        type_code: carTypToEdit?.type_code || "",
        title: carTypToEdit?.title || "",
        min_pax: carTypToEdit?.min_pax || 0,
        max_pax: carTypToEdit?.max_pax || 0,
        meta_tags: carTypToEdit?.meta_tags || "",
        active: carTypToEdit?.active || 0,
    },

    onSubmit: (values) => {
      setLoading(true);
      const newValue: any = { ...values, user_id: user.uid, session_id: user.session_id };
      
      const apiCall = isEdit ? Put.updateCarType : Post.addCarType;
      
      
      apiCall(newValue)
        .then((res) => {
          const curren: any = res.data.currency;
          // currenAdded({ label: curren.code, id: curren.id });
          setLoading(false);
          console.log('on close called');

          onClose();
          console.log('on close called');
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    },
  });

// 
//   useEffect(() => {
//     
//   }, [hotel_type_loading]);
// // 
// //   const searchZonde = (name: string) => {
// //     let form: FormSearchZone = {
// //       count: 20,
// //       key: name,
// //       page: 1,
// //       session_id: user.session_id,
// //       user_id: user.uid,
// //       zone_type: "district",
// //     };
// //     Post.searchZone(form).then((res) => {
// //       set_district_options(res.data.items);
// //       set_district_loading(false);
// //     });
// //   };
//   useEffect(() => {
//     if (district_search) {
//       set_district_loading(true);
//       searchZonde(district_search);
//     }
//   }, [district_search]);
// 
//   const addNewTypeHandler = (newType: OptionType) => {
//     const newOptions = [...options];
//     newOptions.unshift(newType);
//     setOptions([...newOptions]);
//   };
useEffect(() => {
  if (openDia && !isEdit) {
    resetForm({
      values: {
        type_id: 0,
        type_code: "",
        title: "",
        min_pax: 0,
        max_pax: 0,
        meta_tags: "",
        active: 0,
      },
    });
  }
}, [openDia, isEdit, resetForm]);

  return (
    <>
    {/* {!isEdit && (
      // <MDButton onClick={() => setOpen(true)} color={"primary"}>
      //   + Add Currency
      // </MDButton>
    )} */}


      {/* <MDButton onClick={() => setOpen(true)} color={"primary"}>
        + Add Currency
      </MDButton> */}
      <Dialog open={openDia} onClose={onClose} fullWidth maxWidth="md">
        <DialogContent>
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <AddHotelType typeAdded={addNewTypeHandler} />
            </Grid> */}
            <Grid item xs={12}>
              <Box component={"form"} onSubmit={handleSubmit}>
                <Grid item xs={12} spacing={2} container>
                  {/* <Grid item xs={12}>
                    <Autocomplete
                      open={open_hotel_type}
                      onOpen={() => {
                        setOpen_hotel_type(true);
                      }}
                      onClose={() => {
                        setOpen_hotel_type(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.title === value.title}
                      getOptionLabel={(option) => option.title}
                      options={options}
                      loading={hotel_type_loading}
                      onChange={(e, v) => {
                        const v2: any = v;
                        setFieldValue("active", v2.active);
                        setFieldValue("hotel_type", v2.type_id);
                        setFieldValue("type_code", v2.type_code);
                        setFieldValue("type_title", v2.title);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="hotel type"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {hotel_type_loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          value={values.type_title}
                          name="type_title"
                          error={touched.type_title && errors.type_title ? true : false}
                          helperText={touched.type_title && errors.type_title && errors.type_title}
                        />
                      )}
                    />
                  </Grid> */}

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Title"
                      value={values.title}
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.title && errors.title ? true : false}
                      helperText={touched.title && errors.title && errors.title}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Autocomplete
                      open={open_district}
                      onOpen={() => {
                        setOpen_district(true);
                      }}
                      onClose={() => {
                        setOpen_district(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      getOptionLabel={(option) => `${option.name} / ${option.state_name}`}
                      options={district_options}
                      loading={district_loading}
                      onChange={(e, v) => {
                        const v2: any = v;
                        setFieldValue("district", v2.id);
                        setFieldValue("district_name", v2.name);
                        setFieldValue("state", v2.state);
                        setFieldValue("state_name", v2.state_name);
                      }}
                      renderInput={(params) => (
                        <TextField
                          value={values.district}
                          onChange={(e) => set_district_search(e.target.value)}
                          error={touched.state_name && errors.state_name ? true : false}
                          helperText={touched.state_name && errors.state_name && errors.state_name}
                          name="state_name"
                          {...params}
                          label="hotel district"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {district_loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Type Code"
                      value={values.type_code}
                      error={touched.type_code && errors.type_code ? true : false}
                      helperText={touched.type_code && errors.type_code && errors.type_code}
                      name="type_code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Min Pax"
                      value={values.min_pax}
                      error={touched.min_pax && errors.min_pax ? true : false}
                      helperText={touched.min_pax && errors.min_pax && errors.min_pax}
                      name="min_pax"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Max Pax"
                      value={values.max_pax}
                      error={touched.max_pax && errors.max_pax ? true : false}
                      helperText={touched.max_pax && errors.max_pax && errors.max_pax}
                      name="max_pax"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="address"
                      value={values.address}
                      error={touched.address && errors.address ? true : false}
                      helperText={touched.address && errors.address && errors.address}
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid> */}
                  <Grid item xs={12} container spacing={2}>
                    <Grid item>
                      <MDButton loading={loading} variant="gradient" color="dark" type={"submit"}>
                        {(loading && <CircularProgress size="14px" color="primary" />) || (
                          <div> Save </div>
                        )}
                      </MDButton>
                    </Grid>
                    <Grid item>
                      <MDButton
                        variant="outlined"
                        disabled={loading}
                        color={"error"}
                        onClick={() => onClose()}
                      >
                        cancel
                      </MDButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddCarType;
