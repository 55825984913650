import React, { useEffect, useState } from "react";
import { CANCEL, NAME, RESELLER_AGENCY, SEARCH, SELECT_AGENCY_RESELLER } from "./constants";
import FilterAlt from "@mui/icons-material/FilterAlt";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { SELECT_USER } from "store/slices/profile";
import Post from "lib/api/post";
import MDButton from "components/MDButton";
import { Clear } from "@mui/icons-material";
interface ResellerAgencyFilterType {
  setFilterResellerValue: (T: any) => typeof T;
  filterResellerValue: any;
}
const ResellerAgencyFilter = ({
  filterResellerValue,
  setFilterResellerValue,
}: ResellerAgencyFilterType) => {
  const [open, setOpen] = useState(false);
  const [userList, setUserList] = useState(null);
  const [page, setPage] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setInputValue("");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
    p: 2,
    borderRadius: "8px",
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "gray",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const user = SELECT_USER();
  useEffect(() => {
    setIsLoading(true);
    const payload = {
      user_id: user?.uid,
      session_id: user?.session_id,
      key: inputValue,
      page: page,
      count: 5,
    };
    Post.usersList(payload)
      .then((res: any) => {
        setUserList(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [inputValue, page, user?.session_id, user?.uid]);

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleRemove = () => {
    setFilterResellerValue(null);
  };

  return (
    <>
      <Grid item display="flex" py={2} xs={6}>
        <Typography onClick={handleOpen} sx={{ cursor: "pointer" }}>
          {RESELLER_AGENCY}
        </Typography>
        <Box mt={0.5} ml={0.5} onClick={handleOpen} sx={{ cursor: "pointer" }}>
          <FilterAlt />
        </Box>
        {filterResellerValue?.fullname && (
          <>
            <Typography fontSize="14px" ml={2} mt={0.8}>
              {filterResellerValue?.fullname}
            </Typography>
            <Box mt={0.5} ml={2} sx={{ cursor: "pointer" }} onClick={handleRemove}>
              <Clear color="error" />
            </Box>
          </>
        )}
      </Grid>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography>{SELECT_AGENCY_RESELLER}</Typography>
          <Divider sx={{ borderWidth: "1px" }} />
          <TextField
            placeholder={SEARCH}
            fullWidth
            value={inputValue}
            onChange={(e: any) => setInputValue(e.target.value)}
          />
          {isLoading && (
            <Box sx={{ position: "relative" }}>
              <CircularProgress
                size="2.5rem"
                color="secondary"
                sx={{ position: "absolute", left: "50%" }}
              />
            </Box>
          )}
          <Box my={1}>
            <TableContainer component={Paper} sx={{ maxHeight: "40vh" }}>
              <Table sx={{ minWidth: 500 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{NAME}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList?.users?.map((user: any) => (
                    <StyledTableRow
                      key={user?.user_id}
                      onClick={() => {
                        setFilterResellerValue(user);
                        handleClose();
                      }}
                    >
                      <StyledTableCell sx={{ cursor: "pointer" }}>{user?.fullname}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Pagination
            count={Math.ceil(userList?.count / 10)}
            showFirstButton
            showLastButton
            onChange={handlePaginationChange}
            disabled={isLoading}
          />
          <Divider sx={{ borderWidth: "1px" }} />
          <Grid display="flex" justifyContent="flex-end">
            <MDButton color="error" onClick={handleClose}>
              {CANCEL}
            </MDButton>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ResellerAgencyFilter;
