import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Box } from "@mui/system";
import { AppBar, Button, Grid, Paper, Typography } from "@mui/material";
import { CHECK_IN, CHECK_OUT, IN_HOUSE, RESERVATIONS } from "./constants";
import TabPanelContent from "./TabPanelContent";
import Post from "lib/api/post";
import {
  Autorenew,
  KeyboardDoubleArrowLeft,
  VerticalAlignBottom,
  VerticalAlignTop,
  ViewList,
} from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SELECT_USER } from "store/slices/profile";

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const BoardDetail = () => {
  const [value, setValue] = useState(0);
  const [data, setData] = useState(null);

  const [searchParams] = useSearchParams();
  const date = searchParams.get("date");
  const hotel_id = searchParams.get("hotel_id");
  const reseller_id = searchParams.get("reseller_id");

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const navigate = useNavigate();

  const user = SELECT_USER();

  useEffect(() => {
    const payload = {
      user_id: user?.uid,
      session_id: user?.session_id,
      date,
      hotel_id: Number(hotel_id),
      reseller_id: Number(reseller_id),
    };
    Post.boardDetails(payload).then((res) => {
      setData(res.data);
    });
  }, [date, hotel_id, reseller_id, user?.session_id, user?.uid]);

  const backHandleClick = () => {
    return navigate("/board");
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box mt={2}>
        <Paper sx={{ p: 2 }}>
          <Grid justifyContent="center" display="flex" mb={2}>
            {date}
          </Grid>
          <AppBar
            position="static"
            sx={{
              borderRadius: "8px",
              display: "flex",
              flexDirection: "row",
              boxShadow: "none !important",
            }}
          >
            <Button onClick={backHandleClick}>
              <KeyboardDoubleArrowLeft fontSize="large" />
            </Button>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              role="tabpanel"
              aria-label="icon position tabs example"
            >
              <Tab
                label={
                  <Box sx={{ display: "flex" }}>
                    <VerticalAlignBottom fontSize="medium" />
                    <Typography fontSize={14} fontWeight={500} ml={0.5}>
                      {CHECK_IN}
                    </Typography>
                  </Box>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <Box sx={{ display: "flex" }}>
                    <VerticalAlignTop fontSize="medium" />
                    <Typography fontSize={14} fontWeight={500} ml={0.5}>
                      {CHECK_OUT}
                    </Typography>
                  </Box>
                }
                {...a11yProps(1)}
              />
              <Tab
                label={
                  <Box sx={{ display: "flex" }}>
                    <Autorenew fontSize="medium" />
                    <Typography fontSize={14} fontWeight={500} ml={0.5}>
                      {IN_HOUSE}
                    </Typography>
                  </Box>
                }
                {...a11yProps(2)}
              />
              <Tab
                label={
                  <Box sx={{ display: "flex" }}>
                    <ViewList fontSize="medium" />
                    <Typography fontSize={14} fontWeight={500} ml={0.5}>
                      {RESERVATIONS}
                    </Typography>
                  </Box>
                }
                {...a11yProps(3)}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <TabPanelContent
              items={data?.checkin?.items}
              financeData={data?.checkin?.finance}
              roomData={data?.checkin?.room_sta}
              pax={data?.checkin?.pax}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TabPanelContent
              items={data?.checkout?.items}
              financeData={data?.checkout?.finance}
              roomData={data?.checkout?.room_sta}
              pax={data?.checkout?.pax}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TabPanelContent
              items={data?.inhouse?.items}
              financeData={data?.inhouse?.finance}
              roomData={data?.inhouse?.room_sta}
              pax={data?.inhouse?.pax}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <TabPanelContent
              items={data?.reservations?.items}
              financeData={data?.reservations?.finance}
              roomData={data?.reservations?.room_sta}
              pax={data?.reservations?.pax}
            />
          </TabPanel>
        </Paper>
      </Box>
    </DashboardLayout>
  );
};

export default BoardDetail;
