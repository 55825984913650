import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  FormControl,
  TextField,
  Select,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Divider,
  Grid,
  Button,
  TableHead,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { SELECT_USER } from "store/slices/profile";
import {
  FULL_NAME,
  COMPANY,
  USER_TYPE,
  BIRTHDAY,
  GENDER,
  TELEGRAM_ID,
  CITY,
  ADD_USER,
  ADD_CONTACT_ITEM,
  CANCEL,
  SUBMIT,
  CONTACT_DATA,
  MALE,
  FEMALE,
  CONTACT_TYPE,
  REMOVE,
  TITLE,
  VALUE,
} from "../const";
import MDDatePicker from "components/MDDatePicker";
import AddContactItem from "./AddContactItem";
import { styled } from "@mui/material/styles";
import { userTypeData } from "./helper";
import { MODAL_STYLE, SELECT_STYLE } from "../../style";
import moment from "moment";
import Post from "lib/api/post";
import CloseIcon from "@mui/icons-material/Close";
import Put from "lib/api/put";

interface AddUserDialogType {
  open: boolean;
  onClose: (T: any) => typeof T;
  initialData?: any;  // داده‌های اولیه برای حالت ویرایش
}

function AddUserDialog({ open, onClose, initialData }: AddUserDialogType) {
  const [isOpenAddContact, setIsOpenAddContact] = useState(false);
  const [contactData, setContactData] = useState([]);
  const [birthDayValue, setBirthDayValue] = useState(null);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const user = SELECT_USER();
  const { handleSubmit, handleChange,setValues, handleBlur, values } = useFormik<any>({

    initialValues: {
      contacts: "[]",
      fullname: initialData?.fullname || "",
      user_type: initialData?.user_type || "partner",
      company: initialData?.company || "",
      user_id: user?.uid,
      session_id: user?.session_id,
      birthday: initialData?.birthday || null,
      gender: initialData?.gender || "male",
      city: initialData?.city || "",
      telegram: initialData?.telegram || "",
    },



    onSubmit: (value) => {
      const payload: any = {
        company: value.company,
        fullname: value.fullname,
        meta_tags: JSON.stringify({
          birthday: birthDayValue ? moment(birthDayValue[0]).format("YYYY-MM-DD") : null,
          gender: value.gender || "male",
          city: value.city || "",
          telegram: value.telegram || null,
        }),
        user_id: initialData.user_id || null,
        session_id: user.session_id,
        uid: user.uid,
        user_type: value.user_type,
      };
      // اگر در حالت افزودن هستیم، contacts را اضافه می‌کنیم
      if (!initialData) {
        payload.contacts = JSON.stringify(contactData);
      }
      console.log(payload);

      // انتخاب متد مناسب برای ارسال درخواست
      const apiCall = initialData ? Put.updateUser : Post.createUser;

      apiCall(payload).then((data) => {
        onClose(false);
      });
    },
  });

  useEffect(() => {
    if (initialData) {
      setValues({
        fullname: initialData.fullname,
        company: initialData.company,
        user_type: initialData.user_type,
        city: initialData.city,
        telegram: initialData.telegram,
        gender: initialData.gender,
        birthday: initialData.birthday,
      });
      // setContactData(initialData.contacts);
      setBirthDayValue(initialData.birthday);
    }
  }, [initialData, setValues]);


  const handleDeletes = (item: any) => {
    const filteredArray = contactData?.filter(
      (contact) =>
        contact.title !== item.title &&
        contact.value !== item.value &&
        contact.contact_type !== item.contact_type &&
        contact.allow_session !== item.allow_session
    );
    setContactData(filteredArray);
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={MODAL_STYLE} component={"form"} onSubmit={handleSubmit}>
        <Typography>{initialData ? "Edit User" : ADD_USER}</Typography>
        <Divider sx={{ borderWidth: "1px" }} />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box>
                <FormControl fullWidth required>
                  <TextField
                    required
                    label={FULL_NAME}
                    variant="outlined"
                    name="fullname"
                    value={values.fullname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <FormControl fullWidth required>
                  <Select
                    native
                    required
                    label={USER_TYPE}
                    sx={SELECT_STYLE}
                    fullWidth
                    value={values.user_type}
                    placeholder={USER_TYPE}
                    name="user_type"
                    defaultValue="partner"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option aria-label="None" value="" />
                    {userTypeData.map((item, key: number) => (
                      <optgroup label={item.label} key={key}>
                        {item.data.map((opt, index: number) => (
                          <option value={opt.id} key={index}>
                            {opt.label}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box>
                <FormControl fullWidth>
                  <TextField
                    label={COMPANY}
                    variant="outlined"
                    name="company"
                    value={values.company}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <FormControl fullWidth>
                  <MDDatePicker
                    label={BIRTHDAY}
                    input={{ placeholder: BIRTHDAY, sx: { width: "100%" } }}
                    name="birthday"
                    onBlur={handleBlur}
                    options={{ mode: "single" }}
                    onChange={(e: { target: { value: string } }) => setBirthDayValue(e)}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box pl={2.5}>
                <FormControl>
                  <FormLabel>{GENDER}</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="gender"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    defaultValue="male"
                  >
                    <FormControlLabel value="male" control={<Radio />} label={MALE} />
                    <FormControlLabel value="female" control={<Radio />} label={FEMALE} />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <FormControl fullWidth>
                  <TextField
                    label={CITY}
                    variant="outlined"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <FormControl fullWidth>
                  <TextField
                    label={TELEGRAM_ID}
                    variant="outlined"
                    name="telegram"
                    value={values.telegram}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ borderWidth: "1px" }} />
          {!initialData && (
          <>
          <Grid display="flex" justifyContent="space-between">
            <Typography>{CONTACT_DATA}</Typography>
            <Button variant="contained" color="primary" onClick={() => setIsOpenAddContact(true)}>
              {ADD_CONTACT_ITEM}
            </Button>
          </Grid>

          <Grid>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table aria-label="customized table">
                <TableHead sx={{ display: "contents" }}>
                  <TableRow>
                    <TableCell align="left">{CONTACT_TYPE}</TableCell>
                    <TableCell align="left">{TITLE}</TableCell>
                    <TableCell align="left">{VALUE}</TableCell>
                    <TableCell align="right">{REMOVE}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contactData.map((row) => (
                    <StyledTableRow key={row.value}>
                      <StyledTableCell align="left">{row.contact_type}</StyledTableCell>
                      <StyledTableCell align="left">{row.title}</StyledTableCell>
                      <StyledTableCell align="left">{row.value}</StyledTableCell>
                      <StyledTableCell align="right">
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleDeletes(row)}
                        >
                          <CloseIcon />
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          </>
        )}

          <Divider sx={{ borderWidth: "1px" }} />
          <Grid display="flex" justifyContent="flex-end">
            <Box sx={{ mr: 2 }}>
              <Button variant="contained" color="secondary" onClick={() => onClose(false)}>
                {CANCEL}
              </Button>
            </Box>

            <Box>
              <Button variant="contained" color="primary" type="submit">
                {SUBMIT}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Modal>
      <AddContactItem
        onClose={setIsOpenAddContact}
        open={isOpenAddContact}
        contactData={contactData}
        setContactData={setContactData}
      />
    </>
  );
}
export default AddUserDialog;