import { useCallback, useEffect, useMemo, useState } from "react";

// @mui material components
import { Grid, Icon, Card, Link, MenuItem, Menu } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { SELECT_USER } from "store/slices/profile";
import Post from "lib/api/post";
import MDBadge from "components/MDBadge";

import AddCostType from "./add";
interface OptionType {
    id: number;
    title: string;
    order: number;
    reservation: boolean;
    reservation_room: boolean;
    operation: boolean;
    drivers: boolean;
    cars: boolean;
    tours: boolean;
    active: number;
}

function CostTypes(): JSX.Element {
  const user = SELECT_USER();

  const [costTypes, setCostTypes] = useState<readonly OptionType[]>([]);
  const [costId, setCostId] = useState<number>();
  const [openAddCstType, setOpenCstType] = useState(false);
  const [openRemoveCstTyp, setOpenRemoveCstTyp] = useState(false);

  const [_searchedValue, _setSearchedValue] = useState<string>("");
  const [_openMenu, _setOpenMenu] = useState<any>(false);
  const [_updatePage, _setUpdatePage] = useState<number>(0);
  const [selectedCstTyp, setSelectedCstTyp] = useState<OptionType | null>(null);  // برای ذخیره ارز انتخاب‌شده
  const [openEditDialog, setOpenEditDialog] = useState(false);  // برای باز و بسته کردن دیالوگ ویرایش

  const fetchCstTyp = useCallback(() => {
    Post.searchCostType(user.session_id, user.uid, _searchedValue).then((res) => {
      setCostTypes(res.data.items);
    });
  }, [_searchedValue, user.session_id, user.uid]);
  

  const handleEditCstType = (currency: OptionType) => {
    setSelectedCstTyp(currency);  // ارز انتخاب‌شده رو ذخیره می‌کنیم
    setOpenEditDialog(true);  // دیالوگ ویرایش رو باز می‌کنیم
  };
  useEffect(() => {
    if (_searchedValue === "") return;
    fetchCstTyp();  // اولین بار که صفحه بارگذاری می‌شود، لیست ارزها فراخوانی می‌شود
  }, [_searchedValue, fetchCstTyp]);


  const handleCloseMenu = useCallback(() => _setOpenMenu(false), []);

  const handleOpenAddHotel = useCallback(() => {
    handleCloseMenu();
    setOpenCstType(true);
  }, [handleCloseMenu]);

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);  // بستن دیالوگ ویرایش
    fetchCstTyp();  // به‌روزرسانی لیست ارزها بعد از افزودن ارز
  };
  const handleOpenAddDialog = () => {
    setOpenCstType(true);  // باز کردن دیالوگ برای اضافه کردن ارز
  };
  
  const handleCloseAddDialog = () => {
    setOpenCstType(false);  // بستن دیالوگ برای اضافه کردن ارز
    fetchCstTyp();  // به‌روزرسانی لیست ارزها بعد از افزودن ارز
  };

  const handleOpenRemoveCstTyp = useCallback(() => {
    handleCloseMenu();
    setOpenRemoveCstTyp(true);
  }, []);

  const handleOpenMenu = useCallback((event: any, currency: OptionType) => {
    setCostId(currency.id);  // شناسه ارز رو تنظیم می‌کنیم
    setSelectedCstTyp(currency);  // اطلاعات ارز انتخاب‌شده رو تنظیم می‌کنیم
    _setOpenMenu(event.currentTarget);  // منو رو باز می‌کنیم
  }, []);

  const handleRemoveSuccess = () => {
    setOpenRemoveCstTyp(false);
    fetchCstTyp();  // به‌روزرسانی لیست ارزها بعد از حذف ارز
  };

  const renderMenu = useMemo(() => {
    return (
      <>
        <Menu
          anchorEl={_openMenu}
          anchorReference={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={Boolean(_openMenu)}
          onClose={handleCloseMenu}
          sx={{ mt: 2 }}
        >
          {/* برای ویرایش از handleEditCurrency استفاده می‌کنیم */}
          <MenuItem onClick={() => handleEditCstType(selectedCstTyp!)}>
            <Icon sx={{ mr: 1 }}>edit</Icon>Modify
          </MenuItem>
          
          <MenuItem onClick={handleOpenRemoveCstTyp}>
            <Icon sx={{ mr: 1 }}>delete</Icon>Remove
          </MenuItem>
        </Menu>
  
        {openEditDialog && (
        <AddCostType
          costTypeAdded={(v: any): void => {}}
          costTypeToEdit={selectedCstTyp}  // ارسال ارز انتخاب‌شده به AddCurrency
          isEdit={true}
          openDia={openEditDialog}  // کنترل باز بودن دیالوگ
          onClose={handleCloseEditDialog}  // برای بستن دیالوگ
        />
      )}
      </>
    );
  }, [_openMenu, costId, handleEditCstType, handleOpenRemoveCstTyp, handleCloseMenu]);
  
  const renderTable = useMemo(() => {
    return (
      <DataTable
        isSorted={false}
        table={{
          columns: [
            {
              Header: (
                <MDTypography variant="h6" fontWeight="bold">
                  #Title
                </MDTypography>
              ),
              accessor: "title",
            },
            {
              Header: (
                <MDTypography variant="h6" fontWeight="bold">
                  Section
                </MDTypography>
              ),
              accessor: "section",
            },
            {
              Header: <Icon fontSize="medium">view_list</Icon>,
              accessor: "actions",
              width: "12%",
            },
          ],
          rows: costTypes.map((currency, i) => {
            const hotelObj = {
              title: (
                <>
                  <MDTypography variant="inherit" color="text" fontWeight="bold">
                    {currency.title}
                  </MDTypography>
                </>
              ),
              section: (
                <>
                <MDBox display="flex" justifyContent={"space-between"} alignItems="center" gap="5px">
                     <MDBadge
                    size="xs"
                    color={currency.reservation ? "info" : "dark"} // شرط برای reservation
                    badgeContent="Reservation"
                    container
                  />
                     <MDBadge
                    size="xs"
                    color={currency.reservation_room ? "info" : "dark"} // شرط برای reservation
                    badgeContent="Reservation Room"
                    container
                  />
                     <MDBadge
                    size="xs"
                    color={currency.operation ? "info" : "dark"} // شرط برای reservation
                    badgeContent="Operations"
                    container
                  />
                     <MDBadge
                    size="xs"
                    color={currency.drivers ? "info" : "dark"} // شرط برای reservation
                    badgeContent="Drivers"
                    container
                  />
                     <MDBadge
                    size="xs"
                    color={currency.cars ? "info" : "dark"} // شرط برای reservation
                    badgeContent="Cars"
                    container
                  />
                     <MDBadge
                    size="xs"
                    color={currency.tours ? "info" : "dark"} // شرط برای reservation
                    badgeContent="Tours"
                    container
                  />
                  </MDBox>
                </>
              ),

              actions: (
                <>
                  <MDButton
                    id={currency.id.toString()}
                    key={i}
                    variant="contained"
                    color="light"
                    onClick={(e) => handleOpenMenu(e, currency)}  // ارسال currency به handleOpenMenu
                  >
                    <Icon>view_list</Icon>
                    &nbsp;Actions
                  </MDButton>
                </>
              ),
            };
            return hotelObj;
          }),
        }}
        entriesPerPage={false}
        pagination={{
          variant: "contained",
          color: "primary",
        }}
        showTotalEntries={false}
      />
    );
  }, [handleOpenMenu, costTypes]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container>
            <Card sx={{ width: "100%" }}>
              <Grid container p={3} spacing={1}>
                <Grid item sx={{ width: "70%" }}>
                  <MDInput
                    sx={{ width: "100%" }}
                    label="Search"
                    value={_searchedValue}
                    onChange={(e: { target: { value: string } }) =>
                      _setSearchedValue(e.target.value)
                    }
                  ></MDInput>
                </Grid>
                <Grid item sx={{ marginLeft: "auto" }}>
                  
                  <Grid container justifyContent="flex-end">
                  <MDButton onClick={() => setOpenCstType(true)} color={"primary"}>
                      + Add Cost Type
                  </MDButton>
                    <AddCostType  costTypeAdded={(v: any): void => {}} 
                      openDia={openAddCstType}  // ارسال وضعیت باز بودن دیالوگ برای اضافه کردن
                      onClose={handleCloseAddDialog}  // تابع بستن دیالوگ
                      />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {renderMenu}
                {renderTable}
              </Grid>

              {/* {openRemoveCstTyp ? (
                <Grid item xs={12}>
                  <Remove
                    curr_id={costId}
                    open={openRemoveCstTyp}
                    setOpen={setOpenRemoveCstTyp}
                    removedCurr={handleRemoveSuccess}
                  />
                </Grid>
              ) : null} */}
            </Card>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {/* {openEditDialog && (
        <AddCurrency
          currAdded={(v: any): void => {}}
          currencyToEdit={selectedCurrency}  // ارسال ارز انتخاب‌شده به AddCurrency
          isEdit={true}
        />
      )} */}

      
    </>
  );
}

export default CostTypes;
