import { useCallback, useEffect, useMemo, useState } from "react";

// @mui material components
import { Grid, Icon, Card, Link, MenuItem, Menu } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { SELECT_USER } from "store/slices/profile";
import Post from "lib/api/post";
interface OptionType {
  id: number;
name: string;
state: number;
district: number;
code: string;
icao: string;
iata: string;
location: string;
state_name: string;
district_name: string;
}

function Airports(): JSX.Element {
  const user = SELECT_USER();

  const [airports, setAirports] = useState<readonly OptionType[]>([]);
  const [airportId, setAirportId] = useState<number>();
  const [openAddAirpor, setOpenAddAirpor] = useState(false);
  const [openRemoveHotel, setOpenRemoveHotel] = useState(false);

  const [_searchedValue, _setSearchedValue] = useState<string>("");
  const [_openMenu, _setOpenMenu] = useState<any>(false);
  const [_updatePage, _setUpdatePage] = useState<number>(0);
  const [selectedAirport, setSelectedAirport] = useState<OptionType | null>(null);  // برای ذخیره ارز انتخاب‌شده
  const [openEditDialog, setOpenEditDialog] = useState(false);  // برای باز و بسته کردن دیالوگ ویرایش

  const fetchAirports = useCallback(() => {
    Post.searchAirports(user.session_id, user.uid, _searchedValue).then((res) => {
      setAirports(res.data.items);
    });
  }, [_searchedValue, user.session_id, user.uid]);
  

  const handleEditAirport = (currency: OptionType) => {
    setSelectedAirport(currency);  // ارز انتخاب‌شده رو ذخیره می‌کنیم
    setOpenEditDialog(true);  // دیالوگ ویرایش رو باز می‌کنیم
  };
  useEffect(() => {
    if (_searchedValue === "") return;
    fetchAirports();  // اولین بار که صفحه بارگذاری می‌شود، لیست ارزها فراخوانی می‌شود
  }, [_searchedValue, fetchAirports]);


  const handleCloseMenu = useCallback(() => _setOpenMenu(false), []);

  const handleOpenAddHotel = useCallback(() => {
    handleCloseMenu();
    setOpenAddAirpor(true);
  }, [handleCloseMenu]);

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);  // بستن دیالوگ ویرایش
    fetchAirports();  // به‌روزرسانی لیست ارزها بعد از افزودن ارز
  };
  const handleOpenAddDialog = () => {
    setOpenAddAirpor(true);  // باز کردن دیالوگ برای اضافه کردن ارز
  };
  
  const handleCloseAddDialog = () => {
    setOpenAddAirpor(false);  // بستن دیالوگ برای اضافه کردن ارز
    fetchAirports();  // به‌روزرسانی لیست ارزها بعد از افزودن ارز
  };

  const handleOpenRemoveHotel = useCallback(() => {
    handleCloseMenu();
    setOpenRemoveHotel(true);
  }, []);

  const handleOpenMenu = useCallback((event: any, currency: OptionType) => {
    setAirportId(currency.id);  // شناسه ارز رو تنظیم می‌کنیم
    setSelectedAirport(currency);  // اطلاعات ارز انتخاب‌شده رو تنظیم می‌کنیم
    _setOpenMenu(event.currentTarget);  // منو رو باز می‌کنیم
  }, []);

  const handleRemoveSuccess = () => {
    setOpenRemoveHotel(false);
    fetchAirports();  // به‌روزرسانی لیست ارزها بعد از حذف ارز
  };

  const renderMenu = useMemo(() => {
    return (
      <>
        <Menu
          anchorEl={_openMenu}
          anchorReference={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={Boolean(_openMenu)}
          onClose={handleCloseMenu}
          sx={{ mt: 2 }}
        >
          {/* برای ویرایش از handleEditAirport استفاده می‌کنیم */}
          <MenuItem onClick={() => handleEditAirport(selectedAirport!)}>
            <Icon sx={{ mr: 1 }}>edit</Icon>Modify
          </MenuItem>
          
          <MenuItem onClick={handleOpenRemoveHotel}>
            <Icon sx={{ mr: 1 }}>delete</Icon>Remove
          </MenuItem>
        </Menu>
  
        {/* {openEditDialog && (
        <AddAirport
          currAdded={(v: any): void => {}}
          currencyToEdit={selectedAirport}  // ارسال ارز انتخاب‌شده به AddAirport
          isEdit={true}
          openDia={openEditDialog}  // کنترل باز بودن دیالوگ
          onClose={handleCloseEditDialog}  // برای بستن دیالوگ
        />
      )} */}
      </>
    );
  }, [_openMenu, airportId, handleEditAirport, handleOpenRemoveHotel, handleCloseMenu]);
  
  const renderTable = useMemo(() => {
    return (
      <DataTable
        isSorted={false}
        table={{
          columns: [
            {
              Header: (
                <MDTypography variant="h6" fontWeight="bold">
                  #IATA/ICAO
                </MDTypography>
              ),
              accessor: "code",
            },
            {
              Header: (
                <MDTypography variant="h6" fontWeight="bold">
                  Name
                </MDTypography>
              ),
              accessor: "name",
            },
            {
              Header: <Icon fontSize="medium">view_list</Icon>,
              accessor: "actions",
              width: "12%",
            },
          ],
          rows: airports.map((currency, i) => {
            const hotelObj = {
              code: (
                <>
                  <MDTypography variant="inherit" color="text" fontWeight="bold">
                    {currency.iata}/{currency.icao}
                  </MDTypography>
                </>
              ),
              name: (
                <>
                  <MDTypography variant="inherit" color="text" fontWeight="bold">
                    {currency.name}
                  </MDTypography>
                </>
              ),

              actions: (
                <>
                  <MDButton
                    id={currency.id.toString()}
                    key={i}
                    variant="contained"
                    color="light"
                    onClick={(e) => handleOpenMenu(e, currency)}  // ارسال currency به handleOpenMenu
                  >
                    <Icon>view_list</Icon>
                    &nbsp;Actions
                  </MDButton>
                </>
              ),
            };
            return hotelObj;
          }),
        }}
        entriesPerPage={false}
        pagination={{
          variant: "contained",
          color: "primary",
        }}
        showTotalEntries={false}
      />
    );
  }, [handleOpenMenu, airports]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container>
            <Card sx={{ width: "100%" }}>
              <Grid container p={3} spacing={1}>
                <Grid item sx={{ width: "70%" }}>
                  <MDInput
                    sx={{ width: "100%" }}
                    label="Search"
                    value={_searchedValue}
                    onChange={(e: { target: { value: string } }) =>
                      _setSearchedValue(e.target.value)
                    }
                  ></MDInput>
                </Grid>
                <Grid item sx={{ marginLeft: "auto" }}>
                  
                  <Grid container justifyContent="flex-end">
                  <MDButton onClick={() => setOpenAddAirpor(true)} color={"primary"}>
                      + Add Airport
                  </MDButton>
                    {/* <AddAirport  currAdded={(v: any): void => {}} 
                      openDia={openAddAirpor}  // ارسال وضعیت باز بودن دیالوگ برای اضافه کردن
                      onClose={handleCloseAddDialog}  // تابع بستن دیالوگ
                      /> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {renderMenu}
                {renderTable}
              </Grid>
{/* 
              {openRemoveHotel ? (
                <Grid item xs={12}>
                  <Remove
                    curr_id={currId}
                    open={openRemoveHotel}
                    setOpen={setOpenRemoveHotel}
                    removedCurr={handleRemoveSuccess}
                  />
                </Grid>
              ) : null} */}
            </Card>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {/* {openEditDialog && (
        <AddAirport
          currAdded={(v: any): void => {}}
          currencyToEdit={selectedAirport}  // ارسال ارز انتخاب‌شده به AddAirport
          isEdit={true}
        />
      )} */}

      
    </>
  );
}

export default Airports;
