import {
  Box,
  Typography,
  IconButton,
  Card,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CostForm from "./CostForm";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useFormContext } from "../../FormContextProvide";

interface IRoomItemProps {
  room: any;
  index: number;
}
const RoomItem = ({ room, index }: IRoomItemProps) => {
  const { formContext, setFormContext } = useFormContext();

  const currentRoom = formContext?.rooms?.filter((item: any) => item.id === room.id)?.[0];
  const filteredRoom = formContext?.rooms?.filter((item: any) => item.id !== room.id);
  const [order, setOrder] = useState(1);

  const handleAddRow = () => {
    const data = { id: uuidv4(), order };

    const newRooms = [
      ...filteredRoom,
      {
        ...currentRoom,
        extras: [...currentRoom?.extras, data],
      },
    ];
    setFormContext({ ...formContext, rooms: newRooms });
    setOrder((order) => order + 1);
  };

  const handleChangeValue = ({ data, values }: any) => {
    const currentExtra = currentRoom?.extras.filter((extra: any) => extra.id === values?.id)?.[0];
    const filteredExtra = currentRoom?.extras.filter((extra: any) => extra.id !== values?.id);

    const newRooms = [
      ...filteredRoom,
      {
        ...currentRoom,
        extras: [...filteredExtra, { ...currentExtra, ...data }],
      },
    ];
    setFormContext({ ...formContext, rooms: newRooms });
  };

  const handleDelete = (id: string) => {
    const filteredExtra = currentRoom?.extras.filter((extra: any) => extra.id !== id);
    const newRooms = [
      ...filteredRoom,
      {
        ...currentRoom,
        extras: [...filteredExtra],
      },
    ];
    setFormContext({ ...formContext, rooms: newRooms });
  };

  const orderedList = room?.extras.sort((a: any, b: any) => {
    return a.order - b.order;
  });
  return (
    <Box sx={{ backgroundColor: "#e9ecef", p: 2, mb: 3, mt: 3, borderRadius: 4 }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 1,
        }}
      >
        <Box display="flex">
          <Typography>{`${room?.room_type1_title} > ${room?.room_type2_title} #${
            index + 1
          }`}</Typography>
        </Box>
        <Box>
          <IconButton aria-label="add" onClick={handleAddRow}>
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      <Card variant="outlined">
        {orderedList?.map((row: any, key: number) => {
          return (
            <CostForm
              key={key}
              id={row.id}
              handleDelete={handleDelete}
              handleChangeValue={handleChangeValue}
            />
          );
        })}
      </Card>
    </Box>
  );
};

export default RoomItem;
