import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Calendar from "components/customs/Calendar";
import { useCallback, useEffect, useState } from "react";
import { DateClickArg } from "@fullcalendar/interaction";
import { CalendarEventType } from "lib/types/calenderType";
import { ReservationsBoardResponseType } from "lib/types/api-type";
import Post from "lib/api/post";
import { CHECK_IN, CHECK_OUT, VOUCHERS, RESERVATIONS_COUNT, ROOM_COUNT } from "./constants";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import MDDatePicker from "components/MDDatePicker";
import { SELECT_USER } from "store/slices/profile";
import HotelFilter from "./HotelFilter";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ResellerAgencyFilter from "./ResellerAgencyFilter";

function Board(): JSX.Element {
  const user = SELECT_USER();

  const [events, setEvents] = useState<CalendarEventType[]>([]);
  const [data, setData] = useState<ReservationsBoardResponseType>();
  const [datePickerValues, setDatePickerValues] = useState({
    from: moment().subtract(3, "month").format("YYYY-MM-DD"),
    to: moment().add(3, "month").format("YYYY-MM-DD"),
  });
  const [filerHotelValue, setFilerHotelValue] = useState(null);
  const [filterResellerValue, setFilterResellerValue] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const payload = {
      user_id: user?.uid,
      session_id: user?.session_id,
      hotel_id: filerHotelValue?.hotel_id ?? -1,
      reseller_id: filterResellerValue?.user_id ?? -1,
      ...datePickerValues,
    };
    datePickerValues?.from &&
      datePickerValues?.to &&
      Post.reservationsBoard(payload)
        .then((res) => {
          const { data } = res;
          setData(data);
        })
        .catch((err) => console.log(err));
  }, [
    datePickerValues,
    filerHotelValue?.hotel_id,
    filterResellerValue?.user_id,
    user?.session_id,
    user?.uid,
  ]);

  const createEvents = (data: ReservationsBoardResponseType) => {
    const customCheckin: CalendarEventType[] = [];
    const customCheckout: CalendarEventType[] = [];
    const customReservations: CalendarEventType[] = [];
    data?.checkin?.forEach((item: { count: number; date: string }) => {
      customCheckin.push({
        title: `${CHECK_IN}: ${item?.count}`,
        start: item?.date,
        end: item?.date,
        className: "success",
      });
    });
    data?.checkout?.forEach((item: { count: number; date: string }) => {
      customCheckout.push({
        title: `${CHECK_OUT}: ${item?.count}`,
        start: item?.date,
        end: item?.date,
        className: "dark",
      });
    });

    data?.reservations?.forEach((item: { count: number; date: string }) => {
      customReservations.push({
        title: `${VOUCHERS}: ${item?.count}`,
        start: item?.date,
        end: item?.date,
        className: "info",
      });
    });

    setEvents([...customCheckin, ...customCheckout, ...customReservations]);
  };

  useEffect(() => {
    createEvents(data);
  }, [data]);

  const handleNavigate = useCallback(
    (date: string) => {
      return navigate(
        `/board-detail?${`date=${date}&&hotel_id=${filerHotelValue?.hotel_id ?? -1}&&reseller_id=${
          filterResellerValue?.user_id ?? -1
        }`}`
      );
    },
    [filerHotelValue?.hotel_id, filterResellerValue?.user_id, navigate]
  );
  const dateClickHandler = useCallback(
    (e: DateClickArg) => {
      handleNavigate(e?.dateStr);
    },
    [handleNavigate]
  );

  const eventClickHandler = useCallback(
    (e: any) => {
      const date = moment(e).format("YYYY-MM-DD");
      handleNavigate(date);
    },
    [handleNavigate]
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid>
        <Grid bgcolor={"#fff"} borderRadius="10px" p={2} mb={2}>
          <MDDatePicker
            input={{ placeholder: "Reserve Date", sx: { width: "100%" } }}
            options={{
              mode: "range",
              dateFormat: "Y-m-d",
              defaultDate: [datePickerValues?.from, datePickerValues?.to],
            }}
            onChange={(e: any) => {
              if (e.length === 2) {
                setDatePickerValues({
                  from: moment(e[0]).format("YYYY-MM-DD"),
                  to: moment(e[1]).format("YYYY-MM-DD"),
                });
              }
            }}
          />
          <Grid display="flex">
            <HotelFilter
              setFilerHotelValue={setFilerHotelValue}
              filerHotelValue={filerHotelValue}
            />
            <ResellerAgencyFilter
              filterResellerValue={filterResellerValue}
              setFilterResellerValue={setFilterResellerValue}
            />
          </Grid>
        </Grid>
        <Grid>
          <Calendar
            sx={{
              "& .fc-daygrid-day": {
                height: "110px",
              },
            }}
            dateClick={dateClickHandler}
            events={events}
            eventClicked={eventClickHandler}
          />
        </Grid>

        <Grid bgcolor={"#fff"} borderRadius="10px" p={2} mt={2}>
          <MDBox display="flex" gap={1}>
            <MDButton color="success">{`${CHECK_IN}: ${ROOM_COUNT}`}</MDButton>
            <MDButton color="dark">{`${CHECK_OUT}: ${ROOM_COUNT}`}</MDButton>
            <MDButton color="info">{`${RESERVATIONS_COUNT}`}</MDButton>
          </MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Board;
