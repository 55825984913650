import { useState } from "react";
import RoomCard from "./components/RoomCard";
import { Button, Grid, Stack } from "@mui/material";
import { BATCH_JOBS } from "./components/const";
import BatchJobsDialog from "./components/BatchJobsDialog";
import { useFormContext } from "../FormContextProvide";
import { NEXT, PREVIOUS } from "../const";
import MDSnackbar from "components/MDSnackbar";
import { isEmpty } from "lodash";

type Props = {
  next: () => void;
  back: () => void;
  activeStep: number;
};

const PassengersTabContent = ({ next, back, activeStep }: Props) => {
  //hooks
  const { formContext } = useFormContext();

  //states
  const [errors,setErrors] = useState<string[]>([]);
  const [isBatchJobOpen, setIsBatchJobOpen] = useState(false);
  const [openErrorMessage, setOpenErrorMessage] = useState<boolean>(false);
  return (
    <Grid container spacing={3}>
      <Grid
        xs={12}
        item
        style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1rem" }}
      >
        <Button variant="contained" color="primary" onClick={() => setIsBatchJobOpen(true)}>
          {BATCH_JOBS}
        </Button>
        <BatchJobsDialog open={isBatchJobOpen} onClose={() => setIsBatchJobOpen(false)} />
      </Grid>
      <Grid xs={12} item>
        {formContext?.rooms?.map((room: any, index: number) => (
          <RoomCard roomIndex={index} setErrors={setErrors}/>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" py={2} justifyContent="space-between">
          <Button
            variant="contained"
            color="primary"
            disabled={activeStep === 0}
            onClick={back}
            sx={{ mr: 1 }}
          >
            {PREVIOUS}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
                if (isEmpty(errors)) {
                  next?.();
                } else {
                  setOpenErrorMessage(true);
                }
            }}
          >
            {NEXT}
          </Button>
        </Stack>
      </Grid>
      {openErrorMessage &&
        errors.map((error: string) => (
          <MDSnackbar
            color="error"
            icon="notifications"
            title="error from server"
            content={error}
            dateTime=""
            open={true}
            close={() => setOpenErrorMessage(false)}
          />
        ))}
    </Grid>
  );
};

export default PassengersTabContent;
