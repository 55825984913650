import { useCallback, useEffect, useMemo, useState } from "react";

// @mui material components
import { Grid, Icon, Card, Link, MenuItem, Menu, Box, Select, FormControl } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { SELECT_USER } from "store/slices/profile";
import Post from "lib/api/post";
import AddUserDialog from "../../reservation/hotelReservation/HotelTab/components/AddUserDialog";
import { USER_TYPE } from "../../reservation/hotelReservation/HotelTab/const";
import { userTypeData } from "../../reservation/hotelReservation/HotelTab/components/helper";
interface OptionType {
  user_id: number;
fullname: string;
company: string;
user_type: string;
meta_tags: string;
}

function Users(): JSX.Element {
  const user = SELECT_USER();

  const [users, setUsers] = useState<readonly OptionType[]>([]);
  const [uesrId, setUserId] = useState<number>();
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openRemoveHotel, setOpenRemoveHotel] = useState(false);

  const [_searchedValue, _setSearchedValue] = useState<string>("");
  const [_selectedUserType, _setSelectedUserType] = useState<string>("");
  const [_openMenu, _setOpenMenu] = useState<any>(false);
  const [_updatePage, _setUpdatePage] = useState<number>(0);
  const [selectedUser, setSelectedUser] = useState<OptionType | null>(null);  // برای ذخیره ارز انتخاب‌شده
  const [openEditDialog, setOpenEditDialog] = useState(false);  // برای باز و بسته کردن دیالوگ ویرایش

  const fetchUser = useCallback(() => {
    Post.searchUser(user.session_id, user.uid, _searchedValue,_selectedUserType).then((res) => {
      setUsers(res.data.users);
    });
  }, [_searchedValue, user.session_id, user.uid, _selectedUserType]);
  

  const handleEditUser = (user: OptionType) => {
    setSelectedUser(user);  // ارز انتخاب‌شده رو ذخیره می‌کنیم
    setOpenEditDialog(true);  // دیالوگ ویرایش رو باز می‌کنیم
  };
  useEffect(() => {
    if (_searchedValue === "") return;
    fetchUser();  // اولین بار که صفحه بارگذاری می‌شود، لیست ارزها فراخوانی می‌شود
  }, [_searchedValue, fetchUser]);


  const handleCloseMenu = useCallback(() => _setOpenMenu(false), []);

  const handleOpenAddHotel = useCallback(() => {
    handleCloseMenu();
    setOpenAddUser(true);
  }, [handleCloseMenu]);

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);  // بستن دیالوگ ویرایش
    fetchUser();  // به‌روزرسانی لیست ارزها بعد از افزودن ارز
  };
  const handleOpenAddDialog = () => {
    setOpenAddUser(true);  // باز کردن دیالوگ برای اضافه کردن ارز
  };
  
  const handleCloseAddDialog = () => {
    setOpenAddUser(false);  // بستن دیالوگ برای اضافه کردن ارز
    fetchUser();  // به‌روزرسانی لیست ارزها بعد از افزودن ارز
  };

  const handleOpenRemoveUser = useCallback(() => {
    handleCloseMenu();
    setOpenRemoveHotel(true);
  }, []);

  const handleOpenMenu = useCallback((event: any, currency: OptionType) => {
    setUserId(currency.user_id);  // شناسه ارز رو تنظیم می‌کنیم
    setSelectedUser(currency);  // اطلاعات ارز انتخاب‌شده رو تنظیم می‌کنیم
    _setOpenMenu(event.currentTarget);  // منو رو باز می‌کنیم
  }, []);

  const handleRemoveSuccess = () => {
    setOpenRemoveHotel(false);
    fetchUser();  // به‌روزرسانی لیست ارزها بعد از حذف ارز
  };

  const renderMenu = useMemo(() => {
    return (
      <>
        <Menu
          anchorEl={_openMenu}
          anchorReference={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={Boolean(_openMenu)}
          onClose={handleCloseMenu}
          sx={{ mt: 2 }}
        >
          {/* برای ویرایش از handleEditCurrency استفاده می‌کنیم */}
          <MenuItem onClick={() => handleEditUser(selectedUser!)}>
            <Icon sx={{ mr: 1 }}>edit</Icon>Modify
          </MenuItem>
          
          <MenuItem onClick={handleOpenRemoveUser}>
            <Icon sx={{ mr: 1 }}>delete</Icon>Remove
          </MenuItem>
        </Menu>
{/*   
        {openEditDialog && (
        <AddCurrency
          currAdded={(v: any): void => {}}
          currencyToEdit={selectedUser}  // ارسال ارز انتخاب‌شده به AddCurrency
          isEdit={true}
          openDia={openEditDialog}  // کنترل باز بودن دیالوگ
          onClose={handleCloseEditDialog}  // برای بستن دیالوگ
        />
      )} */}
      </>
    );
  }, [_openMenu, uesrId, handleEditUser, handleOpenRemoveUser, handleCloseMenu]);
  
  const renderTable = useMemo(() => {
    return (
      <DataTable
        isSorted={false}
        table={{
          columns: [
            {
              Header: (
                <MDTypography variant="h6" fontWeight="bold">
                  #NAME
                </MDTypography>
              ),
              accessor: "name",
            },
            {
              Header: (
                <MDTypography variant="h6" fontWeight="bold">
                  COMPANY
                </MDTypography>
              ),
              accessor: "company",
            },
            {
              Header: (
                <MDTypography variant="h6" fontWeight="bold">
                  TYPE
                </MDTypography>
              ),
              accessor: "type",
            },
            {
              Header: <Icon fontSize="medium">view_list</Icon>,
              accessor: "actions",
              width: "12%",
            },
          ],
          rows: users.map((currency, i) => {
            const userObj = {
              name: (
                <>
                  <MDTypography variant="inherit" color="text" fontWeight="bold">
                    {currency.fullname}
                  </MDTypography>
                </>
              ),
              company: (
                <>
                  <MDTypography variant="inherit" color="text" fontWeight="bold">
                    {currency.company}
                  </MDTypography>
                </>
              ),
              type: (
                <>
                  <MDTypography variant="inherit" color="text" fontWeight="bold">
                    {currency.user_type}
                  </MDTypography>
                </>
              ),

              actions: (
                <>
                  <MDButton
                    id={currency.user_id.toString()}
                    key={i}
                    variant="contained"
                    color="light"
                    onClick={(e) => handleOpenMenu(e, currency)}  // ارسال currency به handleOpenMenu
                  >
                    <Icon>view_list</Icon>
                    &nbsp;Actions
                  </MDButton>
                </>
              ),
            };
            return userObj;
          }),
        }}
        entriesPerPage={false}
        pagination={{
          variant: "contained",
          color: "primary",
        }}
        showTotalEntries={false}
      />
    );
  }, [handleOpenMenu, users]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container>
            <Card sx={{ width: "100%" }}>
              <Grid container p={5} spacing={1}>
                <Grid item >
                  <MDInput
                    sx={{ width: "100%" }}
                    label="Search"
                    value={_searchedValue}
                    onChange={(e: { target: { value: string } }) =>
                      _setSearchedValue(e.target.value)
                    }
                  ></MDInput>
                </Grid>
                <Grid item xs={12} sm={6}>
              <Box>
                <FormControl fullWidth required>
                  <Select
                    native
                    required
                    label={USER_TYPE}
                    // sx={SELECT_STYLE}
                    fullWidth
                    placeholder={USER_TYPE}
                    name="user_type"
                    defaultValue="partner"
                    value={_selectedUserType}
                    onChange={(e: { target: { value: string } }) =>
                      _setSelectedUserType(e.target.value)
                    }
                    //value={values.user_type}
                    //onChange={handleChange}
                    // onBlur={handleBlur}
                  >
                    <option aria-label="None" value="" />
                    {userTypeData.map((item, key: number) => (
                      <optgroup label={item.label} key={key}>
                        {item.data.map((opt, index: number) => (
                          <option value={opt.id} key={index}>
                            {opt.label}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

                <Grid item sx={{ marginLeft: "auto" }}>
                  
                  <Grid container justifyContent="flex-end">
                  <MDButton onClick={() => setOpenAddUser(true)} color={"primary"}>
                      + Add User
                  </MDButton>
                    <AddUserDialog   
                      open={openAddUser}  // ارسال وضعیت باز بودن دیالوگ برای اضافه کردن
                      onClose={handleCloseAddDialog}  // تابع بستن دیالوگ
                      />
                    <AddUserDialog open={openAddUser} onClose={handleCloseAddDialog} />

                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {renderMenu}
                {renderTable}
              </Grid>
{/* 
              {openRemoveHotel ? (
                <Grid item xs={12}>
                  <Remove
                    curr_id={currId}
                    open={openRemoveHotel}
                    setOpen={setOpenRemoveHotel}
                    removedCurr={handleRemoveSuccess}
                  />
                </Grid>
              ) : null} */}
            </Card>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {openEditDialog && (
        <AddUserDialog
          open={openEditDialog}  // ارسال وضعیت باز بودن دیالوگ برای اضافه کردن
          onClose={handleCloseEditDialog}  // تابع بستن دیالوگ
          initialData={selectedUser}  // ارسال ارز انتخاب‌شده به AddCurrency
        />
      )}

      
    </>
  );
}

export default Users;
