import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  FormControl,
  TextField,
  Select,
  Typography,
  Divider,
  Stack,
  Button,
  Grid,
} from "@mui/material";
import {
  ADD_CONTACT,
  TITLE,
  VALUE,
  CANCEL,
  SUBMIT,
  CONTACT_TYPE,
  NO,
  YES,
  LOGIN_ALLOWED,
} from "../const";
import { useFormik } from "formik";
import { SELECT_USER } from "store/slices/profile";
import { contactTypeData } from "./helper";
import Post from "lib/api/post";
import { MODAL_STYLE, SELECT_STYLE } from "../../style";
import { Switch } from "@material-ui/core";
import Put from "lib/api/put";

interface AddContactDialogType {
  open: boolean;
  onClose: any;
  contactData: any[];
  setContactData: (T: any) => typeof T;
  initialData?: any; // داده‌های اولیه برای حالت ویرایش
}

const AddContactItem = ({ open, onClose, contactData, setContactData, initialData  }: AddContactDialogType) => {
  const [isSwitch, setIsSwitch] = useState(false);
  const user = SELECT_USER();

  const { handleSubmit, handleChange, handleBlur, values, setValues, resetForm } = useFormik<any>({
    initialValues: {
      user_id: user?.uid,
      session_id: user?.session_id,
      allow_session: 0,
      contact_type: "mail",
      contact_value: null,
      title: null,
    },
    onSubmit: (values) => {
      const payload : any = {
        user_id: user?.uid,
        session_id: user?.session_id,
        allow_session: isSwitch ? 1 : 0,
        contact_type: values.contact_type,
        contact_value: values.contact_value,
        title: values.title,
      };

      if (initialData) {
        // برای به روز رسانی مخاطب
        Put.updateUserContact(payload)
          .then((res) => {
            const updatedContact = res.data.contact;
            const updatedContacts = contactData.map((contact) =>
              contact.value === initialData.value ? updatedContact : contact
            );
            setContactData(updatedContacts);
            onClose();
          })
          .catch((err: any) => console.log(err));
      } else {
        // برای اضافه کردن مخاطب جدید
        Post.checkContact(payload)
          .then((res) => {
            const { data } = res;
            if (!data.result) setContactData([...contactData, data?.contact]);
            onClose();
            resetForm();
          })
          .catch((err: any) => console.log(err));
      }
    },
  });

  useEffect(() => {
    if (initialData) {
      setValues({
        user_id: user?.uid,
        session_id: user?.session_id,
        allow_session: initialData.allow_session,
        contact_type: initialData.contact_type,
        contact_value: initialData.contact_value,
        title: initialData.title,
      });
      setIsSwitch(initialData.allow_session === 1);
    }
  }, [initialData, setValues, user?.session_id, user?.uid]);

  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={MODAL_STYLE} component={"form"} onSubmit={handleSubmit}>
      <Typography>{initialData ? "Edit Contact" : ADD_CONTACT}</Typography>
      <Divider sx={{ borderWidth: "1px" }} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box>
              <FormControl fullWidth required>
                <TextField
                  required
                  label={TITLE}
                  variant="outlined"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <FormControl fullWidth required>
                <TextField
                  required
                  label={VALUE}
                  variant="outlined"
                  name="contact_value"
                  value={values.contact_value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <FormControl fullWidth required>
                <Select
                  native
                  required
                  label={CONTACT_TYPE}
                  sx={SELECT_STYLE}
                  fullWidth
                  placeholder={CONTACT_TYPE}
                  name="contact_type"
                  value={values.contact_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option aria-label="None" value="" />
                  {contactTypeData.map((item, key: number) => (
                    <optgroup label={item.label} key={key}>
                      {item.data.map((opt, index: number) => (
                        <option value={opt.id} key={index}>
                          {opt.label}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box mt={0.8}>
              <FormControl fullWidth>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>{LOGIN_ALLOWED}</Typography>
                  <Typography>{NO}</Typography>
                  <Switch
                    {...label}
                    name="allow_session"
                    color="primary"
                    checked={isSwitch}
                    onChange={(e) => setIsSwitch(e.target.checked)}
                  />
                  <Typography>{YES}</Typography>
                </Stack>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ borderWidth: "1px" }} />
        <Grid display="flex" justifyContent="flex-end">
          <Box sx={{ mr: 2 }}>
            <Button variant="contained" color="secondary" onClick={() => onClose(false)}>
              {CANCEL}
            </Button>
          </Box>

          <Box>
            <Button variant="contained" color="primary" type="submit">
              {SUBMIT}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddContactItem;
