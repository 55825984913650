export const CHECK_IN = "Check-in";
export const CHECK_OUT = "Check-out";
export const VOUCHERS = "Vouchers";
export const ROOM_COUNT = "ROOM COUNT";
export const RESERVATIONS_COUNT = "RESERVATIONS COUNT";
export const HOTEL = "Hotel";
export const RESELLER_AGENCY = "Reseller/Agency";
export const SELECT_HOTEL = "Select Hotel";
export const SEARCH = "Search";
export const NAME = "Name";
export const DESCRIPTION = "Description";
export const CANCEL = "Cancel";
export const SELECT_AGENCY_RESELLER = "Select Agency/Reseller";
