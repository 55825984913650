import { Box, Grid, Divider, FormControl, TextField, Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Check, Close } from "@mui/icons-material";
import { AGENCY_RESELLER, RESELLER, VOUCHER } from "./const";
import ResellerAgencyFilter from "./components/ResellerAgencyFilter";
import HotelFilter from "./components/HotelFilter";
import Post from "lib/api/post";
import { SELECT_USER } from "store/slices/profile";
import NewRoomForm from "./components/NewRoomForm";
import Status from "./components/Status";
import { useFormContext } from "../FormContextProvide";
import { NEXT, PREVIOUS } from "../const";
import { isEmpty } from "lodash";
import MDSnackbar from "components/MDSnackbar";

interface VoucherDataType {
  isValid: boolean;
  value: string;
}

type UserType = {
  company: string;
  fullname: string;
  meta_tags: string;
  user_id: number;
  user_type: string;
};
type HotelsType = {
  address: string;
  district: number | string;
  district_name: string;
  fullname: string;
  hotel_code: string;
  hotel_id: number;
  hotel_type: number;
  location: string;
  state: number;
  state_name: string;
  type_code: string;
  type_title: string;
}[];

type Props = {
  next: () => void;
  back: () => void;
  activeStep: number;
};

function HotelTabContent({ next, back, activeStep }: Props) {
  const { formContext, setFormContext } = useFormContext();

  const [errors] = useState<string[]>([]);
  const [openErrorMessage, setOpenErrorMessage] = useState<boolean>(false);

  const user = SELECT_USER();

  const [filterAgencyResellerValue, setFilterAgencyResellerValue] = useState(null);
  const [filterResellerValue, setFilterResellerValue] = useState(null);
  const [filerHotelValue, setFilerHotelValue] = useState([]);

  const [voucherData, setVoucherData] = useState<VoucherDataType>({
    isValid: false,
    value: "",
  });

  useEffect(() => {
    if (openErrorMessage) {
      setTimeout(() => setOpenErrorMessage(false), 5000);
    }
  }, [openErrorMessage]);

  useEffect(() => {
    setFilterAgencyResellerValue({
      company: formContext.agancy_company,
      user_id: formContext.agancy_id,
      fullname: formContext.agancy_name,
    });
    setFilterResellerValue({
      user_id: formContext.resseller_id,
      fullname: formContext.resseller_name,
    });
    setVoucherData({ ...voucherData, value: formContext.voucher });
  }, []);

  const handleVoucher = (e: any) => {
    const payload = { user_id: user?.uid, session_id: user?.session_id, voucher: e.target.value };

    Post.checkVoucher(payload)
      .then((res) => {
        const { data } = res;

        setVoucherData({ value: e.target.value, isValid: data?.valid });
      })
      .catch((err) => console.log(err));
  };

  const CheckRequired = () => {
    if (isEmpty(formContext.voucher)) {
      errors.push("voucher is required");
      return false;
    } else if (!formContext.agancy_id) {
      errors.push("agency is required");
      return false;
    } else if (!formContext.resseller_id) {
      errors.push("reseller is required");
      return false;
    } else if (isEmpty(formContext.hotels)) {
      errors.push("At least you have to choose a hotel");
      return false;
    } else if (isEmpty(formContext.rooms)) {
      errors.push("You have not selected a room yet");
      return false;
    } else return true;
  };

  return (
    <Stack style={{ padding: "30px 10px" }} spacing={3}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <Status />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack direction="row" spacing={1.25} alignItems="center">
            <FormControl required fullWidth>
              <TextField
                required
                fullWidth
                label={VOUCHER}
                variant="outlined"
                onChange={handleVoucher}
                onBlur={() => {
                  voucherData.isValid &&
                    setFormContext({ ...formContext, voucher: voucherData.value });
                }}
              />
            </FormControl>

            <Box mt={4.5} ml={2} sx={{ display: "flex" }}>
              {voucherData?.isValid && voucherData?.value ? (
                <Check htmlColor="green" fontSize="medium" />
              ) : (
                <Close htmlColor="red" fontSize="medium" />
              )}
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ResellerAgencyFilter
            filterResellerValue={filterAgencyResellerValue}
            setFilterResellerValue={(user: UserType) => {
              setFormContext({
                ...formContext,
                agancy_company: user.company,
                agancy_id: user.user_id,
                agancy_name: user.fullname,
                agency_note: "",
              });
              setFilterAgencyResellerValue(user);
            }}
            title={AGENCY_RESELLER}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ResellerAgencyFilter
            filterResellerValue={filterResellerValue}
            setFilterResellerValue={(user: UserType) => {
              setFormContext({
                ...formContext,
                resseller_id: user.user_id,
                resseller_name: user.fullname,
              });
              setFilterResellerValue(user);
            }}
            title={RESELLER}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <HotelFilter
            setFilerHotelValue={(items: HotelsType) => {
              const listID: { id: number }[] = [];
              items.forEach((item) => listID.push({ id: item.hotel_id }));
              setFormContext({ ...formContext, hotels: listID });
              setFilerHotelValue(items);
            }}
            filerHotelValue={filerHotelValue}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ borderWidth: "1px", backgroundColor: "#d2d6da" }} />
        </Grid>
        <Grid item xs={12}>
          <NewRoomForm filerHotelValue={filerHotelValue} />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" py={2} justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              disabled={activeStep === 0}
              onClick={back}
              sx={{ mr: 1 }}
            >
              {PREVIOUS}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const passedFields = CheckRequired();
                if (passedFields) {
                  next?.();
                } else {
                  setOpenErrorMessage(true);
                }
              }}
            >
              {NEXT}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {openErrorMessage &&
        errors.map((error: string) => (
          <MDSnackbar
            color="error"
            icon="notifications"
            title="error from server"
            content={error}
            dateTime=""
            open={true}
            close={() => setOpenErrorMessage(false)}
          />
        ))}
    </Stack>
  );
}

export default HotelTabContent;
