import { useCallback, useEffect, useMemo, useState } from "react";

// @mui material components
import { Grid, Icon, Card, Link, MenuItem, Menu } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { SELECT_USER } from "store/slices/profile";
import FormContextProvider from "layouts/pages/dashboard/reservation/hotelReservation/FormContextProvide";
import Post from "lib/api/post";
import AddState from "./add";
import AddDistrict from "./add";
interface OptionType {
  id: number;
name: string;
state: number;
code: string;
state_name:string;
}

function Districts(): JSX.Element {
  const user = SELECT_USER();

  const [districts, setDistricts] = useState<readonly OptionType[]>([]);
  const [districtId, setDistrictId] = useState<number>();
  const [openAddDistrict, setOpenAddDistrict] = useState(false);
  const [openRemoveDistrict, setOpenRemoveDistrict] = useState(false);

  const [_searchedValue, _setSearchedValue] = useState<string>("");
  const [_openMenu, _setOpenMenu] = useState<any>(false);
  const [_updatePage, _setUpdatePage] = useState<number>(0);
  const [selectedDistrict, setSelectedDistrict] = useState<OptionType | null>(null);  
  const [openEditDialog, setOpenEditDialog] = useState(false); 
  const fetchDistrict = useCallback(() => {
    Post.searchDistrict(user.session_id, user.uid, _searchedValue).then((res) => {
      setDistricts(res.data.items);
    });
  }, [_searchedValue, user.session_id, user.uid]);
  

  const handleEditDistrict = (State: OptionType) => {
    setSelectedDistrict(State);  // ارز انتخاب‌شده رو ذخیره می‌کنیم
    setOpenEditDialog(true);  // دیالوگ ویرایش رو باز می‌کنیم
  };
  useEffect(() => {
    if (_searchedValue === "") return;
    fetchDistrict();  // اولین بار که صفحه بارگذاری می‌شود، لیست ارزها فراخوانی می‌شود
  }, [_searchedValue, fetchDistrict]);


  const handleCloseMenu = useCallback(() => _setOpenMenu(false), []);

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);  // بستن دیالوگ ویرایش
    fetchDistrict();  // به‌روزرسانی لیست ارزها بعد از افزودن ارز
  };
  
  const handleCloseAddDialog = () => {
    setOpenAddDistrict(false);  // بستن دیالوگ برای اضافه کردن ارز
    fetchDistrict();  // به‌روزرسانی لیست ارزها بعد از افزودن ارز
  };

  const handleOpenRemoveDistrict = useCallback(() => {
    handleCloseMenu();
    setOpenRemoveDistrict(true);
  }, []);

  const handleOpenMenu = useCallback((event: any, State: OptionType) => {
    setDistrictId(State.id);  // شناسه ارز رو تنظیم می‌کنیم
    setSelectedDistrict(State);  // اطلاعات ارز انتخاب‌شده رو تنظیم می‌کنیم
    _setOpenMenu(event.currentTarget);  // منو رو باز می‌کنیم
  }, []);


  const renderMenu = useMemo(() => {
    return (
      <>
        <Menu
          anchorEl={_openMenu}
          anchorReference={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={Boolean(_openMenu)}
          onClose={handleCloseMenu}
          sx={{ mt: 2 }}
        >
          {/* برای ویرایش از handleEditState استفاده می‌کنیم */}
          <MenuItem onClick={() => handleEditDistrict(selectedDistrict!)}>
            <Icon sx={{ mr: 1 }}>edit</Icon>Modify
          </MenuItem>
          
          <MenuItem onClick={handleOpenRemoveDistrict}>
            <Icon sx={{ mr: 1 }}>delete</Icon>Remove
          </MenuItem>
        </Menu>
  <FormContextProvider>
        {openEditDialog && (
        <AddDistrict

          DistrictsAdded={(v: any): void => {}}
          districToEdit={selectedDistrict}  // ارسال ارز انتخاب‌شده به AddState
          isEdit={true}
          openDia={openEditDialog}  // کنترل باز بودن دیالوگ
          onClose={handleCloseEditDialog}  // برای بستن دیالوگ
        />
      )}
      </FormContextProvider>
      </>
    );
  }, [_openMenu, districtId, handleEditDistrict, handleOpenRemoveDistrict, handleCloseMenu]);
  
  const renderTable = useMemo(() => {
    return (
      <DataTable
        isSorted={false}
        table={{
          columns: [
            {
              Header: (
                <MDTypography variant="h6" fontWeight="bold">
                  #CODE
                </MDTypography>
              ),
              accessor: "code",
            },
            {
              Header: (
                <MDTypography variant="h6" fontWeight="bold">
                  Name
                </MDTypography>
              ),
              accessor: "name",
            },
            {
              Header: <Icon fontSize="medium">view_list</Icon>,
              accessor: "actions",
              width: "12%",
            },
          ],
          rows: districts.map((State, i) => {
            const hotelObj = {
              code: (
                <>
                  <MDTypography variant="inherit" color="text" fontWeight="bold">
                    {State.code}
                  </MDTypography>
                </>
              ),
              name: (
                <>
                  <MDTypography variant="inherit" color="text" fontWeight="bold">
                    {State.name}
                  </MDTypography>
                  <MDTypography variant="Inherit">{State.state_name}</MDTypography>

                </>
              ),

              actions: (
                <>
                  <MDButton
                    id={State.id.toString()}
                    key={i}
                    variant="contained"
                    color="light"
                    onClick={(e) => handleOpenMenu(e, State)}  // ارسال State به handleOpenMenu
                  >
                    <Icon>view_list</Icon>
                    &nbsp;Actions
                  </MDButton>
                </>
              ),
            };
            return hotelObj;
          }),
        }}
        entriesPerPage={false}
        pagination={{
          variant: "contained",
          color: "primary",
        }}
        showTotalEntries={false}
      />
    );
  }, [handleOpenMenu, districts]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container>
            <Card sx={{ width: "100%" }}>
              <Grid container p={3} spacing={1}>
                <Grid item sx={{ width: "70%" }}>
                  <MDInput
                    sx={{ width: "100%" }}
                    label="Search"
                    value={_searchedValue}
                    onChange={(e: { target: { value: string } }) =>
                      _setSearchedValue(e.target.value)
                    }
                  ></MDInput>
                </Grid>
                <Grid item sx={{ marginLeft: "auto" }}>
                  
                  <Grid container justifyContent="flex-end">
                  <MDButton onClick={() => setOpenAddDistrict(true)} color={"primary"}>
                      + Add District
                  </MDButton>
                  <FormContextProvider>
                    <AddDistrict  DistrictsAdded={(v: any): void => {}} 
                      openDia={openAddDistrict}  // ارسال وضعیت باز بودن دیالوگ برای اضافه کردن
                      onClose={handleCloseAddDialog}  // تابع بستن دیالوگ
                      />
                      </FormContextProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {renderMenu}
                {renderTable}
              </Grid>

              {/* {openRemoveCount ? (
                // <Grid item xs={12}>
                //   <Remove
                //     curr_id={countId}
                //     open={openRemoveCount}
                //     setOpen={setOpenRemoveCount}
                //     removedCurr={handleRemoveSuccess}
                //   />
                // </Grid>
              ) : null} */}
            </Card>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {/* {openEditDialog && (
        <AddState
          currAdded={(v: any): void => {}}
          StateToEdit={selectedState}  // ارسال ارز انتخاب‌شده به AddState
          isEdit={true}
        />
      )} */}

      
    </>
  );
}
export default Districts;