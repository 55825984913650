import { Axios } from "utils/axios";
import { RoomType, UpdateStatusFormType } from "./types";

export interface setFlightTime {
  date: string;
  flight_id: number | string;
  session_id: number;
  time: string;
  user_id: number;
}
const setFlightTime = (form: setFlightTime) => Axios.put("/operations/setFlightTime", form);

export interface SetHotelTime {
  date: string;
  flight_id: number;
  hotel_id: number;
  session_id: number;
  time: string;
  user_id: number;
}

const setHotelTime = (form: SetHotelTime) => Axios.put("/operations/setHotelTime", form);

export interface SetOperationForm {
  date: string;
  session_id: number;
  user_id: number;
  content: string;
}

const setOperation = (form: SetOperationForm) => Axios.put("/operations/setOperation", form);

const updateContact = (form: RoomType) => Axios.put(`/hotels/updateContact`, form);

const updateRoom = (form: RoomType) => Axios.put(`/hotels/updateRoom`, form);

const updateHotel = (form: any) => Axios.put(`/hotels/updateHotel`, form);
const updateCurr = (form: any) => Axios.put(`/zones/updateCurrency`, form);
const updateAirport = (form: any) => Axios.put(`/zones/updateAirport`, form);
const updateFlight = (form: any) => Axios.put(`/flights/updateFlight`, form);
const updateAirline = (form: any) => Axios.put(`/flights/updateAirline`, form);
const updateCountry = (form: any) => Axios.put(`/zones/updateZone`, form);
const updateState = (form: any) => Axios.put(`/zones/updateZone`, form);
const updateDistricts = (form: any) => Axios.put(`/zones/updateZone`, form);
const updateHotelType = (form: any) => Axios.put(`/hotels/updateHotelType`, form);
const updateCostType = (form: any) => Axios.put(`/financial/updateCostType`, form);
const updateCarType = (form: any) => Axios.put(`/cars/updateCarType`, form);
const updateUser = (form: any) => Axios.put(`/users/updateUser`, form);
const updateUserContact = (form: any) => Axios.put(`/users/updateContact`, form);

const updateReservation = (form: any) => Axios.put(`/reservations/updateReservation`, form);

export const updateStatus = (form: UpdateStatusFormType) =>
  Axios.put("/reservations/updatestatus", form);

const Put = {
  setFlightTime,
  setHotelTime,
  updateDistricts,
  updateCarType,
  updateHotelType,
  updateCostType,
  updateAirline,
  setOperation,
  updateRoom,
  updateUser,
  updateUserContact,
  updateContact,
  updateHotel,
  updateStatus,
  updateReservation,
  updateAirport,
  updateFlight,
  updateCurr,
  updateState,
  updateCountry
};

export default Put;
