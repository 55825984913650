import {
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { RESERVATION_EXTRA_COSTS } from "./const";
import CostForm from "./CostForm";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useFormContext } from "../../FormContextProvide";

const Item = () => {
  const { formContext, setFormContext } = useFormContext();

  const [order, setOrder] = useState(1);
  const [tableValue, setTableValue] = useState<any>([]);

  useEffect(() => {
    setFormContext({ ...formContext, extras: tableValue });
  }, [tableValue]);

  const handleChangeValue = ({ data, values }: any) => {
    const filteredArray = tableValue?.filter((obj: any) => obj.id !== values?.id);
    setTableValue?.([...filteredArray, { ...values, ...data }]);
  };

  const orderedList = formContext?.extras.sort((a: any, b: any) => {
    return a.order - b.order;
  });

  const handleAddRow = () => {
    const data = { id: uuidv4(), order };
    setFormContext({ ...formContext, extras: [...formContext.extras, data] });
    setTableValue([...tableValue, data]);
    setOrder((order) => order + 1);
  };
  const handleDelete = (id: string) => {
    const filteredArray = tableValue?.filter((obj: any) => obj.id !== id);
    setTableValue(filteredArray);
  };

  return (
    <Box sx={{ backgroundColor: "#e9ecef", p: 2, mb: 3, mt: 3, borderRadius: 4 }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 1,
        }}
      >
        <Box display="flex">
          <Typography>{RESERVATION_EXTRA_COSTS}</Typography>
        </Box>
        <Box>
          <IconButton aria-label="edit" onClick={() => handleAddRow()}>
            <AddIcon />
          </IconButton>
        </Box>
      </Box>

      {/* <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table aria-label="customized table">
          <TableHead sx={{ display: "contents" }}>
            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell align="left">{TITLE}</TableCell>
              <TableCell align="left">{BUY_PRICE}</TableCell>
              <TableCell align="left">{SELL_PRICE}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody> */}
        {orderedList?.map((row: any) => {
          return (
            <CostForm
              key={row.id}
              id={row.id}
              order={row.order}
              handleDelete={handleDelete}
              handleChangeValue={handleChangeValue}
            />
          );
        })}
      {/* </TableBody>
        </Table>
      </TableContainer> */}
    </Box>
  );
};

export default Item;
