import { CircularProgress, Grid, TextField, Autocomplete as MuiAutocomplete } from "@mui/material";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import Post, { SearchPasssengersFormType, SearchReservationFormType } from "lib/api/post";

import { SearchReservationResponseType } from "lib/types/api-type";

import { useCallback, useEffect, useState } from "react";
import { SELECT_USER } from "store/slices/profile";
import { convertDate } from "utils/dateHandler";
import Autocomplete, { AutoCompleteType } from "../core/AutoComplete";

export const STATUS: AutoCompleteType[] = [
  { title: "All", value: null },
  { title: "Pending", value: 0 },
  { title: "Confirmed", value: 1 },
  { title: "Canceled", value: 2 },
  { title: "No Show", value: 3 },
];

interface FilterType {
  fromdate: Date;
  todate: Date;
  in_house: Date;
  check_in: Date;
  check_out: Date;
  status: AutoCompleteType;
  voucher: any;
  hotel: AutoCompleteType;
  partner: AutoCompleteType;
  passenger: AutoCompleteType;
  reseller: AutoCompleteType;
  arrival_flight: AutoCompleteType;
  departure_flight: AutoCompleteType;
  session_id: number;
  user_id: number;
}

interface PropsType {
  page: number;
  loading: boolean;
  setPage: (page: number) => void;
  updateData: (data: SearchReservationResponseType) => void;
  setLoading: (value: boolean) => void;
}

const ReportFilter = ({ page, loading, updateData, setPage, setLoading }: PropsType) => {
  const user = SELECT_USER();
  const initialFilter: FilterType = {
    fromdate: null,
    todate: null,
    in_house: null,
    check_in: null,
    check_out: null,
    voucher: null,
    status: STATUS[0],
    hotel: { title: "", value: "" },
    passenger: { title: "", value: "" },
    partner: { title: "", value: "" },
    reseller: { title: "", value: "" },
    arrival_flight: { title: "", value: "" },
    departure_flight: { title: "", value: "" },
    session_id: user.session_id,
    user_id: user.uid,
  };
  const [filter, setFilter] = useState<FilterType>(initialFilter);

  const updateFilter = useCallback((key: string, value: any) => {
    setFilter((pre) => ({ ...pre, [key]: value }));
  }, []);

  const serachHotel = (key: string) => {
    return Post.searchHotel(user.session_id, user.uid, key).then((res) => {
      // address: "Asmalı Mescit, Asmalı Mescit Cd. no:20, 34000 Beyoğlu/İstanbul";
      // district: 460;
      // district_name: "Beyoğlu";
      // fullname: " GRITTI HOTEL";
      // hotel_code: "GRT";
      // hotel_id: 398;
      // hotel_type: 1;
      // location: "https://www.google.com/maps/place/Gritti+Hotel+%26+Restaurant/@41.0302674,28.974464,15z/data=!4m2!3m1!1s0x0:0xb53c7772f1f9478f?sa=X&ved=2ahUKEwiiwveVs_fyAhVOhv0HHYhnANoQ_BJ6BAhGEAU";
      // state: 40;
      // state_name: "İstanbul";
      // type_code: "3*";
      // type_title: "3 Star";
      const hotels: { fullname: string; hotel_id: number }[] = res.data.items;
      return hotels.map((h) => ({ title: h.fullname, value: h.hotel_id }));
    });
  };

  const searchPassengers = (key: string) => {
    const form: SearchPasssengersFormType = {
      session_id: user.session_id,
      user_id: user.uid,
      count: 30,
      key: key,
      page: 1,
    };

    return Post.searchPassenengers(form).then((res) => {
      // company: "";
      // fullname: "  GHAHANI AMIRHOSSEIN";
      // meta_tags: '{"birthday":null,"gender":"male","city":"","telegram":null}';
      // user_id: 3903;
      // user_type: "tourist";
      const users: { fullname: string; user_id: number }[] = res.data.users;
      return users.map((u) => ({ title: u.fullname, value: u.user_id }));
    });
  };

  const searchResellers = (key: string) => {
    const form: SearchPasssengersFormType = {
      session_id: user.session_id,
      user_id: user.uid,
      count: 30,
      key: key,
      page: 1,
    };

    return Post.searchByCompany(form).then((res) => {
      // company: "";
      // fullname: "  GHAHANI AMIRHOSSEIN";
      // meta_tags: '{"birthday":null,"gender":"male","city":"","telegram":null}';
      // user_id: 3903;
      // user_type: "tourist";
      const users: { fullname: string; user_id: number }[] = res.data.users;
      return users.map((u) => ({ title: u.fullname, value: u.user_id }));
    });
  };

  const searchFlights = (key: string) => {
    const form: SearchPasssengersFormType = {
      session_id: user.session_id,
      user_id: user.uid,
      count: 30,
      key: key,
      page: 1,
    };
    return Post.searchFlight(form.session_id, form.user_id, form.key).then((res) => {
      // airline: null;
      // flight_airline: null;
      // flight_code: "EK121";
      // flight_destination: "IST";
      // flight_id: 100;
      // flight_origin: "DXB";
      // flight_schedule: "{}";
      // flight_side: "IN";
      // local: 0;
      // meta_tags: "{}";
      const users: { flight_code: string; flight_id: number }[] = res.data.items;
      return users.map((u) => ({ title: u.flight_code, value: u.flight_id }));
    });
  };

  // SearchFlightFormType

  const prepareData = (page: number) => {
    const newFilter: SearchReservationFormType = {
      fromdate: convertDate(filter.fromdate),
      todate: convertDate(filter.todate),
      in_house: convertDate(filter.in_house),
      check_in: convertDate(filter.check_in),
      check_out: convertDate(filter.check_out),
      status: filter.status?.value,
      voucher: filter.voucher ? parseInt(filter.voucher) : null,
      hotel_id: filter.hotel?.value || null,
      reseller_id: filter.reseller?.value || null,
      arrival_flight: filter?.arrival_flight.value || null,
      departure_flight: filter?.departure_flight?.value || null,
      passenger_id: filter?.passenger?.value || null,
      partner_id: filter?.partner?.value || null,
      user_id: filter?.user_id,
      session_id: filter?.session_id,
      page,
    };
    return newFilter;
  };

  const searchHandler = (page: number) => {
    const newFilter = prepareData(page);
    setLoading(true);
    Post.searchReservation(newFilter)
      .then((res) => {
        const data: SearchReservationResponseType = res.data;
        updateData(data);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    searchHandler(1);
  }, []);

  useEffect(() => {
    searchHandler(page);
  }, [page]);

  return (
    <>
      <Grid spacing={1} container sx={{ p: 1 }}>
        <Grid item xs={12}>
          <MDDatePicker
            value={[filter.fromdate || "", filter.todate || ""]}
            input={{ placeholder: "Reserve Date", sx: { width: "100%" } }}
            options={{ mode: "range" }}
            onChange={(e: any) => {
              updateFilter("fromdate", e[0]);
              updateFilter("todate", e[1]);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <MDDatePicker
            value={filter.in_house || ""}
            input={{ placeholder: "in House Date", sx: { width: "100%" } }}
            name={"in_house"}
            options={{ mode: "single" }}
            onChange={(e: any) => updateFilter("in_house", e[0])}
          />
        </Grid>
        <Grid item xs={6}>
          <MuiAutocomplete
            getOptionLabel={(o) => o.title}
            value={filter.status}
            onChange={(e, value) => updateFilter("status", value)}
            options={STATUS}
            renderInput={(params) => <TextField label={"status"} {...params} />}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            sx={{ input: { height: "30px" } }}
            type={"number"}
            name={"voucher"}
            value={filter.voucher || ""}
            label={"Voucher"}
            onChange={(e) => updateFilter("voucher", e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <MDDatePicker
            value={filter.check_in || ""}
            input={{ placeholder: "check-in", sx: { width: "100%" } }}
            name={"check_in"}
            options={{ mode: "single" }}
            onChange={(e: any) => updateFilter("check_in", e[0])}
          />
        </Grid>
        <Grid item xs={6}>
          <MDDatePicker
            value={filter.check_out || ""}
            input={{ placeholder: "check-out", sx: { width: "100%" } }}
            name={"check_out"}
            options={{ placeholder: "single" }}
            onChange={(e: any) => updateFilter("check_out", e[0])}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={filter.hotel}
            onChange={(e) => updateFilter("hotel", e)}
            label={"Hotels"}
            getData={serachHotel}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={filter.passenger}
            onChange={(e) => updateFilter("passenger", e)}
            label={"Passengers"}
            getData={searchPassengers}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={filter.partner}
            onChange={(e) => updateFilter("partner", e)}
            label={"Partner"}
            getData={searchPassengers}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={filter.reseller}
            onChange={(e) => updateFilter("reseller", e)}
            label={"Reseller"}
            getData={searchResellers}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={filter.arrival_flight}
            onChange={(e) => updateFilter("arrival_flight", e)}
            label={"Arrivals"}
            getData={searchFlights}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={filter.departure_flight}
            onChange={(e) => updateFilter("departure_flight", e)}
            label={"Departures"}
            getData={searchFlights}
          />
        </Grid>
        <Grid item xs={12}>
          <MDButton
            color={"primary"}
            variant={"contained"}
            onClick={() => {
              if (page === 1) {
                searchHandler(1);
              } else {
                setPage(1);
              }
            }}
            fullWidth
          >
            search
          </MDButton>
        </Grid>
      </Grid>
    </>
  );
};

export default ReportFilter;
