import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { ADD_NIGHTS, BUY_PRICE, CURRENCY, NIGHTS, SELL_PRICE } from "./const";
import MDDatePicker from "components/MDDatePicker";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useRequirmentContext } from "../../RequirmentContextProvider";
import { useFormContext } from "../../FormContextProvide";
import { diffDaysOfTwoDate } from "utils/helpers";
import { TextField } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

interface ExtrasType {
  // financial: any;
  // setFinancial: any;
  roomIndex: number;
}
const Extras = ({ roomIndex }: ExtrasType) => {
  const { formContext, setFormContext } = useFormContext();
  const { requirmentContext } = useRequirmentContext();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const { handleSubmit, handleChange, handleBlur, values, setValues, setFieldValue } =
    useFormik<any>({
      initialValues: {
        buyPriceCurrency: '{"id":1,"code":"TRY"}',
        sellPriceCurrency: '{"id":1,"code":"TRY"}',
        fromDate: null,
        toDate: null,
      },
      onSubmit: (values, actions) => {
        const from_night = new Date(values?.fromDate);
        const to_night = new Date(values?.toDate);
        if (!values?.fromDate || !values?.toDate || diffDaysOfTwoDate(from_night, to_night) <= 0) {
          alert("please choose nights");
        } else {
          const buyPriceCurrency = JSON.parse(values?.buyPriceCurrency);
          const sellPriceCurrency = JSON.parse(values?.sellPriceCurrency);
          const payload = {
            buyPrice: values.buyPrice,
            sellPrice: values?.sellPrice,
            buyPriceCurrency: buyPriceCurrency?.id,
            sellPriceCurrency: sellPriceCurrency?.id,
            buyPriceCurrencyCode: buyPriceCurrency?.code,
            sellPriceCurrencyCode: sellPriceCurrency?.code,
            from_night: moment(from_night).format("YYYY-MM-DD"),
            to_night: moment(to_night).format("YYYY-MM-DD"),
            nights: diffDaysOfTwoDate(from_night, to_night),
            id: uuidv4(),
          };
          const newRooms = formContext.rooms;
          newRooms[roomIndex] = {
            ...newRooms[roomIndex],
            financial: [...newRooms[roomIndex]?.financial, { ...payload }],
          };
          setFormContext({ ...formContext, rooms: newRooms });
          setValues({
            buyPrice: 0,
            sellPrice: 0,
            fromDate: null,
            toDate: null,
            buyPriceCurrency: '{"id":1,"code":"TRY"}',
            sellPriceCurrency: '{"id":1,"code":"TRY"}',
          });
        }
      },
    });

  const handleDeletes = (item: any) => {
    const filteredArray = formContext.rooms[roomIndex]?.financial?.filter(
      (obj: any) => obj.id !== item.id
    );

    const newRooms = formContext.rooms;
    newRooms[roomIndex] = {
      ...newRooms[roomIndex],
      financial: filteredArray,
    };

    setFormContext({ ...formContext, rooms: newRooms });
  };

  return (
    <Box>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <FormControl required fullWidth>
              <TextField
                required
                type="number"
                label={BUY_PRICE}
                variant="outlined"
                name="buyPrice"
                value={values.buyPrice}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ min: 0 }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl required fullWidth>
              <TextField
                required
                type="number"
                label={SELL_PRICE}
                variant="outlined"
                name="sellPrice"
                value={values.sellPrice}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ min: 0 }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <MDDatePicker
                label={NIGHTS}
                input={{ placeholder: NIGHTS, sx: { width: "100%" } }}
                options={{
                  mode: "range",
                  allowInput: true,
                  defaultDate: [values.fromDate, values.toDate],
                  values: [values.fromDate, values.toDate],
                  onChange: (e: any) => {
                    setFieldValue("fromDate", new Date(e[0]));
                    e?.[1] && setFieldValue("toDate", new Date(e[1]));
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth required>
              <InputLabel id="buyPriceCurrency">{CURRENCY}</InputLabel>
              <Select
                native
                fullWidth
                required
                label={CURRENCY}
                name="buyPriceCurrency"
                id="buyPriceCurrency"
                value={values.buyPriceCurrency}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {requirmentContext?.currencies?.map((item: any) => (
                  <option
                    value={JSON.stringify({
                      id: item.id,
                      code: item.code,
                    })}
                  >
                    {item.code}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth required>
              <InputLabel id="sellPriceCurrency">{CURRENCY}</InputLabel>
              <Select
                native
                fullWidth
                required
                label={CURRENCY}
                name="sellPriceCurrency"
                id="sellPriceCurrency"
                value={values.sellPriceCurrency}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {requirmentContext?.currencies?.map((item: any) => (
                  <option
                    value={JSON.stringify({
                      id: item.id,
                      code: item.code,
                    })}
                  >
                    {item.code}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" type="submit">
              {ADD_NIGHTS}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ borderWidth: "1px", backgroundColor: "#d2d6da" }} />
      <Grid container>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table aria-label="customized table">
            <TableHead sx={{ display: "contents" }}>
              <TableRow>
                <TableCell align="center">{NIGHTS}</TableCell>
                <TableCell align="center">{BUY_PRICE}</TableCell>
                <TableCell align="center">{SELL_PRICE}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formContext.rooms[roomIndex]?.financial?.length > 0 &&
                formContext.rooms[roomIndex]?.financial.map((row: any, key: number) => (
                  <StyledTableRow key={key}>
                    <StyledTableCell align="center">
                      <Stack direction="row" spacing={2} justifyContent="center">
                        <Typography variant="h5">
                          {diffDaysOfTwoDate(row?.from_night, row?.to_night)}
                        </Typography>
                        <Typography variant="caption" color="CaptionText">
                          {`(${row.from_night} to ${row.to_night})`}
                        </Typography>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography variant="h5">
                        {`${Number(row?.buyPrice).toLocaleString()} ${row?.buyPriceCurrencyCode} `}{" "}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography variant="h5">{`${Number(row?.sellPrice).toLocaleString()} ${
                        row?.sellPriceCurrencyCode
                      } `}</Typography>
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => handleDeletes(row)}
                      >
                        <CloseIcon />
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
};

export default Extras;
